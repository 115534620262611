import { ReactNode, useCallback, useMemo } from 'react'
import _ from 'lodash'
import {
  TableProps,
  TableHeaderRowProps,
  TableRowProps,
  Table as VirtualizedTable
} from 'react-virtualized/dist/commonjs/Table'

import {
  defaultTableRowRenderer,
  defaultTableHeaderRowRenderer,
  AutoSizer,
  Index
} from 'react-virtualized'
import styled from 'styled-components'
import BaseRowRenderer from './BaseRowRenderer'
import BaseHeaderRowRenderer from './BaseHeaderRowRenderer'

// Taken from https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/blob/v1/src/__utils__/types.ts
type NonIndexKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K
} extends { [_ in keyof T]: infer U }
  ? U
  : never

// Taken from https://stackoverflow.com/questions/51465182/typescript-remove-index-signature-using-mapped-types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NonIndex<T extends Record<any, any>> = Pick<T, NonIndexKeys<T>>

// Taken from https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/blob/v1/src/components/VirtualTable/VirtualTable.tsx
interface VirtualTableProps extends Partial<NonIndex<TableProps>> {
  // Fixed height of header row
  headerHeight: number

  /**
   * Responsible for rendering a table row given an array of columns:
   * Should implement the following interface: ({
   *   className: string,
   *   columns: any[],
   *   style: any
   * }): node
   */
  headerRowRenderer: (props: Omit<TableHeaderRowProps, 'style'>) => ReactNode

  // Fixed row height
  rowHeight: number

  // Disables scrolling within the table and displays all rows at once
  fixedHeight: boolean

  /**
   * Responsible for rendering a table row given an array of columns:
   * Should implement the following interface: ({
   *   className: string,
   *   columns: Array,
   *   index: number,
   *   isScrolling: boolean,
   *   onRowClick: ?Function,
   *   onRowDoubleClick: ?Function,
   *   onRowMouseOver: ?Function,
   *   onRowMouseOut: ?Function,
   *   rowData: any,
   *   style: any
   * }): node
   */
  rowRenderer: (props: Omit<TableRowProps, 'style'>) => ReactNode

  /** List of items to be rendered on the table */
  items: object[]

  /** styled-component classes */
  className?: string

  /** One or more Columns describing the data displayed in this row */
  children?: ReactNode
}

interface IStyledVirtualTable extends TableProps {
  isRowClickable: boolean
}

const VirtualTable = (props: VirtualTableProps) => {
  const {
    className = '',
    rowHeight = 42,
    headerHeight = 40,
    fixedHeight,
    rowRenderer = defaultTableRowRenderer,
    headerRowRenderer = defaultTableHeaderRowRenderer,
    items = []
  } = props

  const VTRowRenderer = useCallback(
    (rowRendererArgs: TableRowProps) => {
      const { style, key } = rowRendererArgs

      const dynamicStyle = {
        ...style,
        //  width and paddingRight will be inherited from the container instead
        width: undefined,
        paddingRight: undefined
      }

      return (
        <BaseRowRenderer style={dynamicStyle} key={key}>
          {rowRenderer({
            ...(_.omit(rowRendererArgs, 'style') as TableRowProps)
          })}
        </BaseRowRenderer>
      )
    },
    [rowRenderer]
  )

  const VTHeaderRowRenderer = useCallback(
    (headerRowRendererArgs: TableHeaderRowProps) => {
      const { style } = headerRowRendererArgs
      return (
        <BaseHeaderRowRenderer style={style}>
          {headerRowRenderer({
            ...(_.omit(headerRowRendererArgs, 'style') as TableHeaderRowProps)
          })}
        </BaseHeaderRowRenderer>
      )
    },
    [headerRowRenderer]
  )

  const rowGetter = useCallback(({ index }: Index) => items[index], [items])
  const rowCount = items.length

  const rest = useMemo(
    () => _.omit(props, ['rowRenderer', 'headerRowRenderer', 'children', 'className']),
    [props]
  )

  const getHeight = useCallback(
    (autoSizerHeight: number) => {
      return fixedHeight ? headerHeight + rowHeight * rowCount : autoSizerHeight
    },
    [fixedHeight, headerHeight, rowHeight, rowCount]
  )

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          rowRenderer={VTRowRenderer}
          headerRowRenderer={VTHeaderRowRenderer}
          rowCount={rowCount}
          rowGetter={rowGetter}
          height={getHeight(height)}
          width={width}
          className={className}
          {...rest}
        >
          {props.children}
        </VirtualizedTable>
      )}
    </AutoSizer>
  )
}

// Note: React exposes the following static class-names
// https://github.com/bvaughn/react-virtualized/blob/master/docs/Table.md#class-names
const StyledVirtualTable = styled(VirtualTable)<IStyledVirtualTable>`
  // SHARED STYLES
  .ReactVirtualized__Table__Grid,
  .ReactVirtualized__Table__sortableHeaderColumn {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Table__headerColumn {
    margin: 0;
    padding: 0px 8px;
  }

  // HEADER STYLES
  .ReactVirtualized__Table__headerRow {
    display: flex;
    align-items: stretch;
    height: 100%;
    padding: 0px 32px;
    overflow: auto;
    scrollbar-gutter: stable;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    color: var(--color-grey-08);
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    &:first-of-type {
      padding-left: 0px;
    }
    &:last-of-type {
      padding-right: 0px;
    }
  }

  // GRID STYLES
  .ReactVirtualized__Table__row,
  .ReactVirtualized__Table__rowColumn {
    font-size: inherit;
    line-height: inherit;
    white-space: break-spaces;
  }

  .ReactVirtualized__Table__row {
    overflow: auto;
    scrollbar-gutter: stable;
    display: flex;
    align-items: stretch;
    height: 100%;
    padding: 8px 32px;
    cursor: ${({ isRowClickable = false }) => (isRowClickable ? 'pointer' : 'initial')};
    &:hover {
      background-color: ${({ isRowClickable = false }) =>
        isRowClickable ? 'var(--color-grey-01)' : 'none'};
    }
  }

  .ReactVirtualized__Table__rowColumn {
    display: flex;
    align-items: center;
    &:first-of-type {
      padding-left: 0px;
    }
    &:last-of-type {
      padding-right: 0px;
    }
  }
`

export default StyledVirtualTable
