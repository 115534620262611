import _ from 'lodash'
import PageContent from 'client/components/PageContent/PageContent'
import Button from 'client/components/Button/Button'
import FormField from 'client/components/Form/FormField/FormField'
import { gql, useQuery } from '@apollo/client'
import { GQLExhibit, GQLGuide, GQLMuseum } from 'shared/graphql/types/graphql'
import { useMemo, useState } from 'react'
import { ISelectBoxOptions } from 'client/components/Form/SelectBox/SelectBox'
import MuseumVersionType from 'shared/MuseumVersionType'
import {
  FormSection,
  PageContentContainer,
  StyledSelectBox
} from 'client/screens/Developer/styledComponents'
import useDeveloperPost from './useDeveloperPost'

type SourceGuide = Pick<GQLGuide, 'id' | 'name' | 'visibilityType'>

const ALL_GUIDES = gql`
  query allGuides {
    guides {
      id
      name
      visibilityType
    }
  }
`
type SourceGuideWithVersions = {
  id: number
  versions: {
    id: GQLMuseum['id']
    title: GQLMuseum['title']
    archiveDate: GQLMuseum['archiveDate']
    versionType: MuseumVersionType
  }[]
}

type SourceMuseumWithExhibits = {
  id: number
  exhibits: {
    id: GQLExhibit['id']
    title: GQLExhibit['title']
  }[]
}

const GUIDE_WITH_VERSIONS = gql`
  query sourceGuideWithVersions($id: Int!) {
    guide(id: $id) {
      versions {
        id
        title
        versionType
        archiveDate
      }
    }
  }
`

const SOURCE_MUSEUM_WITH_EXHIBITS = gql`
  query sourceMuseumWithExhibits($id: Int!) {
    museum(id: $id) {
      exhibits {
        id
        title
      }
    }
  }
`

interface IOption {
  value: number
  label: string
}

const CopyExhibitToMuseum = () => {
  const [selectedSourceGuideOption, setSelectedSourceGuideOption] = useState<ISelectBoxOptions>()
  const [selectedSourceMuseumOption, setSelectedSourceMuseumOption] = useState<IOption>()
  const [selectedSourceExhibitOption, setSelectedSourceExhibitOption] = useState<IOption>()
  const [selectedDestinationGuideOption, setSelectedDestinationGuideOption] = useState<IOption>()
  const [selectedDestinationMuseumOption, setSelectedDestinationMuseumOption] = useState<IOption>()

  const { data: allGuidesData } = useQuery<{ guides: SourceGuide[] }>(ALL_GUIDES)

  const { data: sourceGuideWithVersionsData } = useQuery<{ guide: SourceGuideWithVersions }>(
    GUIDE_WITH_VERSIONS,
    {
      skip: !selectedSourceGuideOption,
      variables: { id: selectedSourceGuideOption?.value }
    }
  )

  const { data: sourceMuseumWithExhibitsData } = useQuery<{ museum: SourceMuseumWithExhibits }>(
    SOURCE_MUSEUM_WITH_EXHIBITS,
    {
      skip: !selectedSourceMuseumOption,
      variables: { id: selectedSourceMuseumOption?.value }
    }
  )

  const { data: destinationGuideWithVersionsData } = useQuery<{ guide: SourceGuideWithVersions }>(
    GUIDE_WITH_VERSIONS,
    {
      skip: !selectedDestinationGuideOption,
      variables: { id: selectedDestinationGuideOption?.value }
    }
  )

  const sourceGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .orderBy('name')
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} (${guide.id})`
        }))
        .filter((guide) => guide.value !== selectedDestinationGuideOption?.value)
        .value(),
    [allGuidesData?.guides, selectedDestinationGuideOption?.value]
  )

  const sourceMuseumOptions = useMemo(
    () =>
      _(sourceGuideWithVersionsData?.guide.versions)
        .orderBy(['archiveDate'], ['desc'])
        .map((version) => {
          const dateLabel =
            version.versionType === MuseumVersionType.ARCHIVE
              ? ` - ${new Date(version.archiveDate!).toLocaleString()}`
              : ''
          return {
            value: version.id,
            label: `${version.title} (${
              version.id
            }) - ${version.versionType.toUpperCase()}${dateLabel}`
          }
        })
        .value(),
    [sourceGuideWithVersionsData?.guide.versions]
  )

  const sourceExhibitOptions = useMemo(
    () =>
      _(sourceMuseumWithExhibitsData?.museum.exhibits)
        .orderBy('title')
        .map((exhibit) => {
          const parser = new DOMParser()
          const doc = parser.parseFromString(exhibit.title, 'text/html')
          const { textContent } = doc.body

          return {
            value: exhibit.id,
            label: `(${exhibit.id}) ${textContent}`
          }
        })
        .value(),
    [sourceMuseumWithExhibitsData?.museum.exhibits]
  )

  const destinationGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .filter((guide) => {
          return guide.id !== selectedSourceGuideOption?.value
        })
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} (${guide.id})`,
          guideId: guide.id
        }))
        .sortBy('label')
        .value(),
    [allGuidesData?.guides, selectedSourceGuideOption]
  )

  const destinationMuseumOptions = useMemo(
    () =>
      _(destinationGuideWithVersionsData?.guide.versions)
        .orderBy(['archiveDate'], ['desc'])
        .map((version) => {
          const dateLabel =
            version.versionType === MuseumVersionType.ARCHIVE
              ? ` - ${new Date(version.archiveDate!).toLocaleString()}`
              : ''
          return {
            value: version.id,
            label: `${version.title} (${
              version.id
            }) - ${version.versionType.toUpperCase()}${dateLabel}`
          }
        })
        .value(),
    [destinationGuideWithVersionsData?.guide.versions]
  )

  const [copyExhibitToMuseum] = useDeveloperPost(
    '/api/developer/copy-exhibit-to-museum',
    'Copy Exhibit to Museum',
    () => window.location.reload()
  )

  return (
    <>
      <div>
        <p>Important info:</p>
        <ul>
          <li>
            The copied exhibit from source guide will be <b>merged</b> to the destination museum.
          </li>
          <li>
            Content UUIDs will be regenerated.{' '}
            <b>
              Copied exhibit will exclude exhibit/item lookup number, mapLocation, related content,
              linkedGuideId, and externalId/externalUrl.
            </b>
          </li>
          <li>
            If source exhibit has one or multiple translations, the copied exhibit will only have 1
            translation which is the default language of the destination museum
          </li>
          <li>Copy is done as a pgboss job, please check jobs table to confirm completion</li>
        </ul>
      </div>

      <FormSection>
        <FormField label="Source Guide">
          <StyledSelectBox
            placeholder="Select a guide..."
            options={sourceGuideOptions}
            value={selectedSourceGuideOption}
            onChange={(e) => {
              const entry = _.find(sourceGuideOptions, { value: e.target.value })
              setSelectedSourceGuideOption(entry)
              setSelectedSourceMuseumOption(undefined)
              setSelectedSourceExhibitOption(undefined)
            }}
          />
        </FormField>
        <FormField label="Source Museum" key={selectedSourceMuseumOption?.value}>
          <StyledSelectBox
            isDisabled={_.isNil(selectedSourceGuideOption)}
            placeholder="Select a museum..."
            options={sourceMuseumOptions}
            value={selectedSourceMuseumOption}
            onChange={(e) => {
              const entry = _.find(sourceMuseumOptions, { value: e.target.value })
              setSelectedSourceMuseumOption(entry)
              setSelectedSourceExhibitOption(undefined)
            }}
          />
        </FormField>
        <FormField label="Source Exhibit" key={selectedSourceExhibitOption?.value}>
          <StyledSelectBox
            isDisabled={_.isNil(selectedSourceMuseumOption)}
            placeholder="Select an exhibit..."
            options={sourceExhibitOptions}
            value={selectedSourceExhibitOption}
            onChange={(e) => {
              const entry = _.find(sourceExhibitOptions, { value: e.target.value })
              setSelectedSourceExhibitOption(entry)
            }}
          />
        </FormField>
      </FormSection>

      <FormSection>
        <FormField label="Destination Guide">
          <StyledSelectBox
            placeholder="Select a guide..."
            options={destinationGuideOptions}
            value={selectedDestinationGuideOption}
            onChange={(e) => {
              const entry = _.find(destinationGuideOptions, { value: e.target.value })
              setSelectedDestinationGuideOption(entry)
              setSelectedDestinationMuseumOption(undefined)
            }}
          />
        </FormField>
        <FormField label="Destination Museum" key={selectedDestinationMuseumOption?.value}>
          <StyledSelectBox
            isDisabled={_.isNil(selectedDestinationGuideOption)}
            placeholder="Select a museum..."
            options={destinationMuseumOptions}
            value={selectedDestinationMuseumOption}
            onChange={(e) => {
              const entry = _.find(destinationMuseumOptions, { value: e.target.value })
              setSelectedDestinationMuseumOption(entry)
            }}
          />
        </FormField>
      </FormSection>
      <FormField>
        <Button
          disabled={
            !selectedSourceMuseumOption?.value ||
            !selectedDestinationMuseumOption?.value ||
            !selectedSourceExhibitOption?.value
          }
          onClick={() => {
            copyExhibitToMuseum({
              sourceExhibitId: selectedSourceExhibitOption?.value,
              sourceMuseumId: selectedSourceMuseumOption?.value,
              destinationMuseumId: selectedDestinationMuseumOption?.value
            })
          }}
          label="Copy Exhibit to Destination Museum"
        />
      </FormField>
    </>
  )
}

export default function CopyExhibit() {
  return (
    <PageContent title="Copy Exhibit">
      <PageContentContainer>
        <CopyExhibitToMuseum />
      </PageContentContainer>
    </PageContent>
  )
}
