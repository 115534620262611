import { useMemo } from 'react'
import { AdvancedMarker } from '@vis.gl/react-google-maps'
import { updatePin } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import DocentTippy from 'client/dsm/Tooltip/DocentTippy'
import _ from 'lodash'
import BuildingTooltipMenu from 'client/screens/AppEditor/MapEditor/MapLocation/MapLocationTooltip/BuildingTooltipMenu'
import FloorTooltipMenu from 'client/screens/AppEditor/MapEditor/MapLocation/MapLocationTooltip/FloorTooltipMenu'
import { FloorMapLocationTooltip } from 'client/screens/AppEditor/MapEditor/MapLocation/FloorMapLocation'
import { ExteriorMapLocationTooltip } from 'client/screens/AppEditor/MapEditor/MapLocation/BuildingMapLocation'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import { useDispatch } from 'react-redux'
import { IGoogleMapsPinData, ThemeType } from 'client/types'
import PinIconActiveSVGElement from 'client/assets/svg/icon/ic_map_pin_active.svg'
import BuildingSVG from 'client/assets/svg/icon/ic_map_building.svg'
import { PinIcon } from 'client/screens/AppEditor/MapEditor/styledComponents'

interface ITooltipElementProps {
  locationId: number
}

const TooltipElement = (props: ITooltipElementProps) => {
  const { locationId: mapLocationId } = props
  const buildings = useBuildingList()
  const buildingsByMapLocationId = useMemo(() => {
    return _.keyBy(buildings, 'mapLocationId')
  }, [buildings])
  const isBuilding = Boolean(buildingsByMapLocationId[mapLocationId])

  return isBuilding ? (
    <ExteriorMapLocationTooltip locationId={mapLocationId} menuPopout={BuildingTooltipMenu} />
  ) : (
    <FloorMapLocationTooltip locationId={mapLocationId} menuPopout={FloorTooltipMenu} />
  )
}
interface IMarkerProps {
  location: IGoogleMapsPinData
  mapId: number
}

const GoogleMapsMarker = ({ location: pinData, mapId }: IMarkerProps) => {
  const dispatch = useDispatch()

  const buildings = useBuildingList()
  const buildingsByMapLocationId = useMemo(() => {
    return _.keyBy(buildings, 'mapLocationId')
  }, [buildings])
  const isBuilding = Boolean(buildingsByMapLocationId[pinData.id])

  return (
    <AdvancedMarker
      key={pinData.key}
      position={pinData.location}
      onDragEnd={async (e: google.maps.MapMouseEvent) => {
        if (!e.latLng) {
          return
        }
        await updatePin(
          mapId,
          { id: pinData.id, latitude: e.latLng.lat(), longitude: e.latLng.lng() },
          dispatch
        )
      }}
    >
      <DocentTippy
        content={<TooltipElement locationId={pinData.id} />}
        trigger="click"
        interactive={true}
        size="medium"
        zIndex={9999}
        themeType={ThemeType.LIGHT}
        appendTo={document.body}
      >
        <PinIcon offset={{ x: 0, y: 0 }} show={true}>
          {isBuilding ? <BuildingSVG /> : <PinIconActiveSVGElement />}
        </PinIcon>
      </DocentTippy>
    </AdvancedMarker>
  )
}

export default GoogleMapsMarker
