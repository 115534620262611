import SearchInput from 'client/components/Form/SearchInput/SearchInput'
import styled from 'styled-components'
import { Body1CSS, Body2CSS } from 'client/components/TextStyles'
import { CONTENT_SCREEN_SIZE_WITHOUT_PADDING } from 'client/constants'

export const ButtonContainer = styled.div`
  margin-left: var(--spacing-small);
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  > button {
    width: 236px;
  }

  > button + button {
    margin-left: var(--spacing-small);
  }
`

export const TableWrapper = styled.div<{
  isEmpty?: boolean
  isDragAreaActive?: boolean
}>`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 0;
  overflow: auto;
  min-width: ${({ isEmpty }) => (isEmpty ? '100%' : `${CONTENT_SCREEN_SIZE_WITHOUT_PADDING}px`)};
  ${({ isDragAreaActive }) => {
    if (isDragAreaActive) {
      return `
        background-color: var(--color-white);
        opacity: 0.1;
      `
    }
  }}
`

export const FilterSearch = styled(SearchInput)`
  width: 100%;
  max-width: 280px;
`

export const HeaderTextContainer = styled.div`
  display: inline;
`

export const StyledLabel = styled.span`
  ${Body1CSS};
  margin: 0px;
  margin-left: var(--spacing-xsmall);
  vertical-align: 1px;
`

export const BackdropView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

export const Thumbnail = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const SubText = styled.div`
  ${Body2CSS};
  margin-top: 4px;
  color: var(--color-grey-07);
`
