import isSvg from 'is-svg'
import _ from 'lodash'
import { SUPPORTED_LOGO_MIME_TYPES } from 'shared/constants/images'

export default async function isValidLogo(file: File) {
  const { type } = file

  if (!_.includes(SUPPORTED_LOGO_MIME_TYPES, type)) {
    return false
  }

  if (type === 'image/svg+xml') {
    const text = await file.text()
    return isSvg(text)
  }

  return true
}
