import FormField from 'client/components/Form/FormField/FormField'
import ImageUploaderField from 'client/screens/AppEditor/ImageUpload/ImageUploaderField'
import AddImageAsset, { IOptionFileInput } from 'client/screens/AppEditor/ImageUpload/AddImageAsset'
import { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import {
  MuseumImageType,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_LOGO_FORMATS
} from 'shared/constants/images'
import {
  OrganizationImageContextualHelp,
  RectangularLogoContextualHelp,
  SplashLogoContextualHelp,
  SquareLogoContextualHelp
} from 'client/screens/AppEditor/BrandAssets/ContextualHelp'
import { t } from 'client/i18n'
import i18n from 'i18next'

const IMAGE_TYPE_MAP = {
  [MuseumImageType.EXPLORE_HERO]: {
    label: t('Organization Image'),
    contextualHelp: OrganizationImageContextualHelp,
    minWidth: 1290,
    minHeight: 796,
    isSvgAllowed: false
  },
  [MuseumImageType.EXPLORE_ICON]: {
    label: t('Square Logo'),
    contextualHelp: SquareLogoContextualHelp,
    minWidth: 315,
    minHeight: 315,
    isSvgAllowed: false
  },
  [MuseumImageType.MUSEUM_LOGO]: {
    label: t('Rectangular Logo'),
    contextualHelp: RectangularLogoContextualHelp,
    minWidth: 970,
    minHeight: 550,
    isSvgAllowed: true
  },
  [MuseumImageType.SPLASH_BACKGROUND]: {
    label: t('* Full-Screen Image'),
    contextualHelp: undefined,
    minWidth: 1290,
    minHeight: 2796,
    isSvgAllowed: false
  },
  [MuseumImageType.SPLASH_LOGO]: {
    label: t('Splash Logo'),
    contextualHelp: SplashLogoContextualHelp,
    minWidth: 970,
    minHeight: 550,
    isSvgAllowed: true
  }
}

interface IImageFormFieldProps {
  type: MuseumImageType
  src?: string | null
  fileInputOption?: IOptionFileInput
  additionalOptions?: IMenuOption[]
  error?: string
}

function formatFileTypes(isSvgAllowed: boolean) {
  return new Intl.ListFormat(i18n.language, { type: 'disjunction' }).format(
    isSvgAllowed ? SUPPORTED_LOGO_FORMATS : SUPPORTED_IMAGE_FORMATS
  )
}

const ImageFormField = ({
  type,
  src,
  fileInputOption,
  additionalOptions,
  error
}: IImageFormFieldProps) => {
  const isSplashAsset =
    type === MuseumImageType.SPLASH_BACKGROUND || type === MuseumImageType.SPLASH_LOGO
  const { label, contextualHelp, minWidth, minHeight, isSvgAllowed } = IMAGE_TYPE_MAP[type]

  const description = (
    <>
      {minWidth === minHeight
        ? t('squareImageMinimumDimensions', { minWidth, minHeight })
        : t('imageMinimumDimensions', { minWidth, minHeight })}
      <p style={{ paddingTop: 4, margin: 0, color: '#696969', fontSize: 12, lineHeight: '16px' }}>
        {formatFileTypes(isSvgAllowed)}
      </p>
    </>
  )

  return (
    <FormField label={label} additionalLabelNode={contextualHelp} description={description}>
      {isSplashAsset ? (
        <AddImageAsset
          id={type}
          src={src}
          fileInputOption={fileInputOption!}
          additionalOptions={additionalOptions}
          error={error}
        />
      ) : (
        <ImageUploaderField
          type={type}
          isSvgAllowed={isSvgAllowed}
          width={type === MuseumImageType.EXPLORE_ICON ? 112 : 196}
        />
      )}
    </FormField>
  )
}

export default ImageFormField
