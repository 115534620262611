import { ChangeEventHandler, useCallback, useContext, useEffect, useRef } from 'react'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import useField from 'client/hooks/useField'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import { SUPPORTED_VIDEO_MIME_TYPES } from 'shared/constants/video'
import _ from 'lodash'
import tusdUpload from 'client/util/tusdUpload'
import { SpinnerSizeType } from 'client/dsm/Spinner/Spinner'
import { t } from 'client/i18n'
import VideoFileMenuPopout from 'client/screens/Catalog/forms/VideoForm/VideoFileMenuPopout'
import resolveUnknownError from 'shared/util/resolveUnknownError'
import { SUPPORTED_IMAGE_MIME_TYPES } from 'shared/constants/images'
import {
  AddVideoFileButton,
  ExplanatoryText,
  StyledVideoContainer,
  StyledVideoPlayer,
  TranscodingContainer,
  TranscodingProgressSpinner
} from './styledComponents'
import VideoContextualHelp from './VideoContextualHelp'
import ProcessingErrorMessage from '../shared/ProcessingErrorMessage'

type ObjectUrlType = 'file' | 'posterImageFile'

const VideoFileField = () => {
  const hiddenPosterImageInputRef = useRef<HTMLInputElement>(null)
  const hiddenVideoInputRef = useRef<HTMLInputElement>(null)
  const videoPlayerRef = useRef<HTMLVideoElement>(null)
  const {
    getFieldName,
    getFieldValue,
    setFieldValue,
    setLoadingStatus,
    onError,
    isDefaultLocaleSelected,
    contentId
  } = useContext(TranslationFormContext)

  const file = getFieldValue('file')
  const fileObjectUrl = file?.objectUrl
  const url = getFieldValue('url')
  const sourceUrl = getFieldValue('sourceUrl')

  const videoSrc = fileObjectUrl || url || sourceUrl

  const posterImageSrc =
    getFieldValue('posterImageFile')?.objectUrl || getFieldValue('posterUrlWithoutFallback')

  const revokeObjectUrl = useCallback(
    (field: ObjectUrlType) => {
      const objectUrl = getFieldValue(field)?.objectUrl
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    },
    // TODO this seems to work as we have it, but the linter complains that we're missing the deps
    // commented out below. Simply adding those deps breaks video uploading because getFieldValue
    // gets redefined every time we call it and ends up triggering the effect below, so we'll
    // leave this for now and come back to it
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* getFieldValue */
    ]
  )

  // revoke objectUrls during unmounting
  useEffect(
    () => () => {
      revokeObjectUrl('file')
      revokeObjectUrl('posterImageFile')
    },
    [revokeObjectUrl]
  )

  useEffect(() => {
    if (videoPlayerRef.current && !isDefaultLocaleSelected) {
      videoPlayerRef.current.pause()
    }
  }, [videoPlayerRef, isDefaultLocaleSelected])

  const name = getFieldName('file')
  const { error, touched } = useField(name)

  const processingError = getFieldValue('processingError')
  const isProcessing = getFieldValue('isProcessing')

  const handleVideoChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const videoFile = event.target?.files?.[0]
    if (!videoFile) {
      return
    }
    revokeObjectUrl('file')
    setFieldValue('file', {
      file: videoFile,
      objectUrl: URL.createObjectURL(videoFile)
    })
  }

  const handlePosterImageChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const posterFile = event.target?.files?.[0]

    if (!posterFile) {
      return
    }

    setLoadingStatus(true)

    try {
      const uploadUrl = await tusdUpload(posterFile)
      revokeObjectUrl('posterImageFile')
      const objectUrl = URL.createObjectURL(posterFile)
      setFieldValue('posterImageFile', { name: posterFile.name, url: uploadUrl, objectUrl })
    } catch (e) {
      const err = resolveUnknownError(e)
      onError(err)
      // eslint-disable-next-line no-console
      console.error(err)
    } finally {
      setLoadingStatus(false)
    }
  }

  return (
    <FormField label={t('* Video')} additionalLabelNode={VideoContextualHelp} translatable={false}>
      <input
        ref={hiddenVideoInputRef}
        hidden={true}
        name="videoInput"
        type="file"
        accept={SUPPORTED_VIDEO_MIME_TYPES.join(', ')}
        onChange={handleVideoChange}
      />
      {_.isNil(url || sourceUrl || file) && !processingError ? (
        <>
          <ExplanatoryText>{t('Recommended resolution (min.): 720p')}</ExplanatoryText>
          <AddVideoFileButton
            label={t('Add Video File')}
            onClick={() => hiddenVideoInputRef.current!.click()}
          />
          {error && touched && <ErrorMessage name={name} />}
        </>
      ) : (
        <>
          <ExplanatoryText>
            {t(
              'Recommended resolution (min.): 720p. Click the “More Actions” menu to add/replace the poster image.'
            )}
          </ExplanatoryText>
          <StyledVideoContainer>
            <StyledVideoPlayer
              ref={videoPlayerRef}
              error={!file && processingError}
              src={videoSrc}
              poster={posterImageSrc}
              controls={true}
              controlsList="nodownload noplaybackrate"
              disablePictureInPicture={true}
              key={`${url || sourceUrl}`}
            />
            <input
              ref={hiddenPosterImageInputRef}
              type="file"
              accept={SUPPORTED_IMAGE_MIME_TYPES.join(',')}
              onChange={handlePosterImageChange}
              hidden={true}
            />
            <VideoFileMenuPopout
              id={contentId}
              isProcessing={isProcessing}
              processingError={processingError}
              posterImageSrc={posterImageSrc}
              onReplace={() => hiddenVideoInputRef.current!.click()}
              onAddReplacePosterImage={() => hiddenPosterImageInputRef.current!.click()}
              onDeletePosterImage={() => {
                setFieldValue('posterUrlWithoutFallback', null)
                setFieldValue('posterImageFile', null)
              }}
              localFile={file}
            />
          </StyledVideoContainer>
          {isProcessing && (
            <TranscodingContainer>
              <TranscodingProgressSpinner size={SpinnerSizeType.SMALL} />
              <span>{t('Processing...')}</span>
            </TranscodingContainer>
          )}
          <ProcessingErrorMessage name="file" contentName="video" />
        </>
      )}
    </FormField>
  )
}

export default VideoFileField
