import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import _ from 'lodash'
import { t } from 'client/i18n'
import * as React from 'react'
import { Nil } from 'shared/util/types'

interface IVideoFileMenuPopoutProps {
  id: number | Nil
  posterImageSrc: string
  onAddReplacePosterImage: () => void
  onDeletePosterImage: () => void
  onReplace: () => void
  localFile: any
  isProcessing: boolean
  processingError: boolean
}
function VideoFileMenuPopout(props: IVideoFileMenuPopoutProps) {
  const {
    id,
    posterImageSrc,
    onAddReplacePosterImage,
    onDeletePosterImage,
    onReplace,
    isProcessing,
    processingError,
    localFile
  } = props
  const hasPosterImage = !_.isNil(posterImageSrc)

  const canDownload = id && !localFile

  const options: IMenuOption[] = _.compact([
    {
      label: hasPosterImage ? t('Replace Poster Image') : t('Add Poster Image'),
      onClick: () => onAddReplacePosterImage()
    },
    hasPosterImage && {
      label: t('Delete Poster Image'),
      onClick: () => onDeletePosterImage()
    },
    !isProcessing && {
      label: t('Replace Video'),
      onClick: () => onReplace()
    },
    canDownload && {
      label: t('Download Original Video'),
      onClick: () => window.open(`/api/download/video/${id}/sourceUrl`)
    },
    canDownload && {
      label: t('Download Video'),
      onClick: () => window.open(`/api/download/video/${id}/url`),
      disabled: isProcessing || processingError,
      disabledTooltip: isProcessing
        ? t('Video is processing')
        : t('Unable to download the video file with errors.')
    }
  ])

  return <MenuPopout options={options} />
}

export default React.memo(VideoFileMenuPopout)
