import { useState, useEffect } from 'react'
import { useApiIsLoaded, useMap, MapControl, ControlPosition } from '@vis.gl/react-google-maps'
import _ from 'lodash'
import { IGoogleMapsPinData } from 'client/types'
import ViewControls from 'client/components/ViewControls/ViewControls'
import { GOOGLE_MAPS_MIN_ZOOM, GOOGLE_MAPS_MAX_ZOOM } from 'client/constants'

interface IGoogleMapsControlsProps {
  locations: IGoogleMapsPinData[]
}
const GoogleMapsControls = ({ locations }: IGoogleMapsControlsProps) => {
  const apiIsLoaded = useApiIsLoaded()
  const map = useMap()
  const [zoom, setZoom] = useState<number>()
  const mapExists = !_.isNil(map)

  useEffect(() => {
    let zoomChangedEventListener: google.maps.MapsEventListener | undefined
    if (mapExists) {
      setZoom(map.getZoom())
      zoomChangedEventListener = map.addListener('zoom_changed', () => {
        setZoom(map.getZoom())
      })
    }

    return () => {
      if (zoomChangedEventListener) {
        zoomChangedEventListener.remove()
      }
    }
    // Only need to set initial zoom and add event listener when the map first loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapExists])

  return apiIsLoaded && mapExists ? (
    <MapControl position={ControlPosition.RIGHT_BOTTOM}>
      <ViewControls
        disableZoomingIn={zoom === GOOGLE_MAPS_MAX_ZOOM}
        onZoomIn={() =>
          mapExists &&
          _.isNumber(zoom) &&
          map.setZoom(zoom + 1 > GOOGLE_MAPS_MAX_ZOOM ? GOOGLE_MAPS_MAX_ZOOM : zoom + 1)
        }
        disableZoomingOut={zoom === GOOGLE_MAPS_MIN_ZOOM}
        onZoomOut={() =>
          mapExists &&
          _.isNumber(zoom) &&
          map.setZoom(zoom - 1 < GOOGLE_MAPS_MIN_ZOOM ? GOOGLE_MAPS_MIN_ZOOM : zoom - 1)
        }
        disableResetting={_.isEmpty(locations)}
        onReset={() => {
          const bounds = new google.maps.LatLngBounds()
          locations.forEach(({ location }) => bounds.extend(location))
          map.fitBounds(bounds)
        }}
      />
    </MapControl>
  ) : null
}

export default GoogleMapsControls
