import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import { useFormikContext } from 'formik'
import { useQuery } from '@apollo/client'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { useMemo, useState } from 'react'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import _ from 'lodash'
import UserPermissionType from 'shared/UserPermissionType'
import FormField from 'client/components/Form/FormField/FormField'
import { RadioButton, RadioButtonGroup } from 'client/components/RadioButton/RadioButton'
import { FeatureFlagKey } from 'shared/FeatureFlags'
import { useNavigate } from 'react-router-dom'
import { useDelete, usePost } from 'client/hooks/api'
import * as yup from 'yup'
import styled from 'styled-components'
import { GQLFeatureFlag, GQLGuide, GQLUser } from 'shared/graphql/types/graphql'
import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import extractDisplayErrorMessage from 'client/util/extractDisplayErrorMessage'
import { IErrorMap } from 'client/types'
import EnhancedStandardFormResourceType from 'shared/EnhancedStandardFormResourceType'
import { TableCellProps } from 'react-virtualized'
import CheckmarkSVG from 'client/assets/svg/icon/check_20.svg'
import XSVG from 'client/assets/svg/icon/x_20.svg'
import { refetchActiveQueries } from 'client/apollo'
import GridPlusIconAddButton from '../Catalog/grids/shared/GridPlusIconAddButton'
import {
  FIXED_COLUMN_MEDIUM_PROPS,
  FLEXIBLE_COLUMN_SMALL_PROPS,
  UPDATED_AT_COLUMN
} from '../Catalog/grids/shared/columns'

const HeaderContainer = styled.div`
  margin-bottom: var(--spacing-xsmall);
`

const EmptyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-small); ;
`

const RadioButtonContainer = styled(RadioButtonGroup)<{ hasError?: boolean }>`
  ${({ hasError }) =>
    hasError &&
    `
        border: 1px solid var(--color-status-error);
        padding: var(--spacing-xsmall);
    `}
`

type FormValuesType = {
  name: string | null
  value: boolean | null
  userId: number | null
  guideId: number | null
  permissionType: string | null
  updatedAt: string | null
}

const FORM_INITIAL_VALUES: FormValuesType = {
  name: null,
  value: null,
  userId: null,
  guideId: null,
  permissionType: null,
  updatedAt: null
}

const featureFlagValidationSchema = yup.object().shape({
  name: yup.string().nullable().required(),
  value: yup.boolean().nullable().required(),
  userId: yup.string().nullable(),
  guideId: yup.string().nullable(),
  permissionType: yup.string().nullable(),
  // Adding custom field in order for the error message to show
  targetType: yup.string().test({
    name: 'zero-or-one-target-type-allowed',
    message: 'Please choose one or none of User, Guide, or Permission Type',
    test: (v, context) => {
      const { userId, guideId, permissionType } = context.parent
      return [userId, guideId, permissionType].filter((d) => d).length <= 1
    }
  })
})

const FeatureFlagFormView = ({ users, guides }: { users: GQLUser[]; guides: GQLGuide[] }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext<FormValuesType>()

  const permissionTypeOptions = useMemo(
    () =>
      _(UserPermissionType)
        .map((value, key) => ({
          value,
          label: key
        }))
        .sortBy('label')
        .value(),
    []
  )
  const featureFlagOptions = useMemo(
    () =>
      _(FeatureFlagKey)
        .map((value, key) => ({
          value,
          label: key
        }))
        .sortBy('label')
        .value(),
    []
  )
  const userOptions = useMemo(
    () =>
      _(users)
        .map((user) => ({
          value: user.id,
          label: `[${user.id}]  [${user.email}]  ${user.firstName}, ${user.lastName}`
        }))
        .sortBy('value')
        .value(),
    [users]
  )
  const guideOptions = useMemo(
    () =>
      _(guides)
        .map((guide) => ({
          value: guide.id,
          label: `[${guide.id}]  [${guide.code}] ${guide.name}  `
        }))
        .sortBy('value')
        .value(),
    [guides]
  )

  return (
    <>
      <FormField label="* Name" headerContainer={HeaderContainer}>
        <SelectBox
          name="name"
          options={featureFlagOptions}
          onChange={(e) => setFieldValue('name', e.target.value)}
          value={
            featureFlagOptions.find((o) => o.value === values.name) ?? {
              label: values.name!,
              value: values.name!
            }
          }
          hasError={!!(touched.name && errors.name)}
          placeholder="Select a feature flag"
        />
      </FormField>

      <FormField label="* Value" headerContainer={HeaderContainer}>
        <RadioButtonContainer
          name="value"
          value={values.value}
          onChange={(value) => {
            setFieldValue('value', value === 'true')
          }}
          hasError={!!(touched.value && errors.value)}
        >
          <RadioButton value={true} label="True" />
          <RadioButton value={false} label="False" />
        </RadioButtonContainer>
      </FormField>

      <FormField
        label="User"
        headerContainer={HeaderContainer}
        disabled={!!(values.guideId || values.permissionType)}
        disabledTooltip="Only one or none of User, Guide or Permission Type is allowed"
      >
        <SelectBox
          name="userId"
          options={userOptions}
          onChange={({ target }) =>
            // if cleared, target will be undefined, set it to null so we also clear it out in the backend
            setFieldValue('userId', target.value === undefined ? null : target.value)
          }
          value={userOptions.find((o) => o.value === values.userId)}
          isClearable={true}
          isDisabled={!!(values.guideId || values.permissionType)}
          placeholder="Select a user"
        />
      </FormField>

      <FormField
        label="Guide"
        headerContainer={HeaderContainer}
        disabled={!!(values.userId || values.permissionType)}
        disabledTooltip="Only one or none of User, Guide or Permission Type is allowed"
      >
        <SelectBox
          name="guideId"
          options={guideOptions}
          onChange={({ target }) =>
            // if cleared, target will be undefined, set it to null so we also clear it out in the backend
            setFieldValue('guideId', target.value === undefined ? null : target.value)
          }
          value={guideOptions.find((o) => o.value === values.guideId)}
          isClearable={true}
          isDisabled={!!(values.userId || values.permissionType)}
          placeholder="Select a guide"
        />
      </FormField>

      <FormField
        label="Permission Type"
        headerContainer={HeaderContainer}
        disabled={!!(values.userId || values.guideId)}
        disabledTooltip="Only one or none of User, Guide or Permission Type is allowed"
      >
        <SelectBox
          name="permissionType"
          options={permissionTypeOptions}
          onChange={({ target }) => {
            // if cleared, target will be undefined, set it to null so we also clear it out in the backend
            setFieldValue('permissionType', target.value === undefined ? null : target.value)
          }}
          value={permissionTypeOptions.find((o) => o.value === values.permissionType)}
          isClearable={true}
          isDisabled={!!(values.userId || values.guideId)}
          placeholder="Select a permission type"
        />
      </FormField>
    </>
  )
}
const FeatureFlagForm = ({ users, guides }: { users: GQLUser[]; guides: GQLGuide[] }) => {
  const id = useNumericRouteParam('id')
  const navigate = useNavigate()
  const { data } = useQuery(
    gql`
      query featureFlagsGridRow($id: Int!) {
        featureFlag(id: $id) {
          id
          name
          value
          userId
          guideId
          permissionType
          updatedAt
        }
      }
    `,
    { variables: { id }, skip: !id }
  )
  const [serverErrors, setServerErrors] = useState<IErrorMap | undefined>()

  const goBack = () => {
    refetchActiveQueries()
    navigate('..')
  }
  const [createFeatureFlag] = usePost('/api/developer/feature-flag', {
    onSuccess: goBack,
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error('Server error saving feature flag form: ', e)
      setServerErrors({ server: `Server Error: ${extractDisplayErrorMessage(e)}` })
    }
  })

  const [updateFeatureFlag] = usePost(`/api/developer/feature-flag/${id}`, {
    onSuccess: goBack
  })

  const [deleteFeatureFlag] = useDelete(`/api/developer/feature-flag/${id}`, {
    onSuccess: goBack
  })

  const initialValues = useMemo(() => data?.featureFlag || FORM_INITIAL_VALUES, [data?.featureFlag])

  const handleDelete = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this entry?')
    if (isConfirmed) {
      await deleteFeatureFlag()
    }
  }

  const handleSave = async (values: FormValuesType) => {
    const postReq = id ? updateFeatureFlag : createFeatureFlag
    await postReq(values)
  }

  return (
    <EnhancedStandardForm
      resourceType={EnhancedStandardFormResourceType.FEATURE_FLAG}
      contentId={id}
      onSubmit={handleSave}
      onDelete={handleDelete}
      initialValues={initialValues}
      validationSchema={featureFlagValidationSchema}
      extraErrors={serverErrors}
    >
      <FeatureFlagFormView users={users} guides={guides} />
    </EnhancedStandardForm>
  )
}

function isValidFeatureFlag(featureFlag: GQLFeatureFlag) {
  return Object.keys(FeatureFlagKey).includes(featureFlag.name)
}

function IsValidRenderer(cellProps: TableCellProps) {
  return isValidFeatureFlag(cellProps.rowData as GQLFeatureFlag) ? (
    <CheckmarkSVG style={{ color: 'var(--color-green-07)' }} />
  ) : (
    <XSVG style={{ color: 'var(--color-red-07)' }} />
  )
}

export default function FeatureFlagsGrid() {
  const { data } = useQuery<{ users: GQLUser[]; guides: GQLGuide[] }>(
    gql`
      query featureFlagsGridUserAndGuides {
        users {
          id
          email
          firstName
          lastName
        }
        guides {
          id
          name
          code
        }
      }
    `
  )

  const addButtonNode = useMemo(
    () => <GridPlusIconAddButton label="Add Feature Flag" to="./new" />,
    []
  )

  const featureFlagsGridPlaceholderNode = useMemo(
    () => (
      <EmptyContentContainer>
        <p>Add Feature Flags to get started!</p>
        {addButtonNode}
      </EmptyContentContainer>
    ),
    [addButtonNode]
  )

  return (
    <BaseGridView
      contentName="Feature Flag"
      rowHeight={64}
      gqlQuery={gql`
        query featureFlagsGrid {
          featureFlags {
            id
            name
            value
            user {
              id
              email
            }
            guide {
              id
              code
            }
            permissionType
            updatedAt
          }
        }
      `}
      columns={[
        {
          dataKey: 'name',
          label: 'Valid?',
          cellRenderer: IsValidRenderer,
          ...FIXED_COLUMN_MEDIUM_PROPS,
          sortBy: isValidFeatureFlag
        },
        { dataKey: 'name', label: 'Name' },
        { dataKey: 'value', label: 'Value', ...FLEXIBLE_COLUMN_SMALL_PROPS },
        { dataKey: 'user.email', label: 'User', ...FLEXIBLE_COLUMN_SMALL_PROPS },
        { dataKey: 'guide.code', label: 'Guide', ...FLEXIBLE_COLUMN_SMALL_PROPS },
        { dataKey: 'permissionType', label: 'Permission Type', ...FLEXIBLE_COLUMN_SMALL_PROPS },
        UPDATED_AT_COLUMN
      ]}
      placeholder={featureFlagsGridPlaceholderNode}
      formComponent={
        data?.users && data?.guides
          ? () => <FeatureFlagForm users={data.users} guides={data.guides} />
          : undefined
      }
      buttons={addButtonNode}
    />
  )
}
