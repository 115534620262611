import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import styled from 'styled-components'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import { Body1CSS } from 'client/components/TextStyles'
import { SUPPORTED_LOGO_FORMATS } from 'shared/constants/images'

const WrappingText = styled.div`
  ${Body1CSS};
  white-space: pre-wrap;
  && {
    margin-bottom: 16px;
  }
`

const InvalidLogoFileErrorDialog = ({ onClose }: { onClose: () => void }) => {
  return (
    <Dialog>
      <DialogHeader>{t('Incorrect File Type')}</DialogHeader>
      <DialogBody>
        <WrappingText>
          {t(
            'Only __formats__ file types can be uploaded into this field. Please choose a different image and try again.',
            {
              formats: SUPPORTED_LOGO_FORMATS,
              type: 'disjunction'
            }
          )}
        </WrappingText>
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} type="primary" label={t('Close')} />
      </DialogActions>
    </Dialog>
  )
}

export default InvalidLogoFileErrorDialog
