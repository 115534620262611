import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { GQLMuseumLocation } from 'shared/graphql/types/graphql'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { useNavigate } from 'react-router-dom'
import { refetchActiveQueries } from 'client/apollo'
import { useDelete } from 'client/hooks/api'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { useState } from 'react'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { t } from 'client/i18n'
import { Tag } from 'client/components/ContentRow/styledComponents'
import { ReorderableRowWrapper } from 'client/components/ContentRow/ReorderableRowWrapper'
import { ContentDetails, Detail } from './RowUI'
import DeleteLocationConfirmationDialog from './DeleteLocationConfirmationDialog'

const LocationDetailTitle = styled(Detail)`
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-black);
`

const MenuPopoutContainer = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  position: relative;
`

const StyledTag = styled(Tag)`
  margin-right: var(--spacing-medium);
`

// NOTE: This logic is partially duplicated in the mobile app codebase as well
// It would be nice to be able to share this in the future
function renderLocationDetails(location: GQLMuseumLocation, showName: boolean) {
  const { fullAddress, name } = location

  return (
    <>
      {showName && <LocationDetailTitle>{name}</LocationDetailTitle>}
      {fullAddress}
    </>
  )
}

interface IMuseumLocationRowProps {
  location: GQLMuseumLocation
  isDefaultAndOneOfSeveralLocations: boolean
  showGrabber?: boolean
  showName?: boolean
}

const MuseumLocationRow = ({
  location,
  isDefaultAndOneOfSeveralLocations,
  showGrabber = false,
  showName = false
}: IMuseumLocationRowProps) => {
  const { id } = location

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorDialog, setError] = useErrorDialog()
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)

  const navigateToEditLocationForm = () => navigate(`/app-editor/about/location/${id}`)

  const [deleteLocation] = useDelete(`/locations/${id}`, {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: (error) => setError({ title: t('Unable to Delete Location'), error })
  })

  const options: IMenuOption[] = [
    { label: t('Edit Location'), onClick: navigateToEditLocationForm },
    { label: t('Delete Location'), onClick: () => setShowConfirmDeleteDialog(true) }
  ]

  const isPrimaryLocation = isDefaultAndOneOfSeveralLocations && location.isNavigable

  return (
    <>
      {errorDialog}
      <Wrapper>
        <ReorderableRowWrapper showGrabber={showGrabber}>
          <ContentDetails>{renderLocationDetails(location, showName)}</ContentDetails>
          {isPrimaryLocation && <StyledTag>{t('Primary Location')}</StyledTag>}
          <MenuPopoutContainer>
            <MenuPopout options={options} />
          </MenuPopoutContainer>
        </ReorderableRowWrapper>
      </Wrapper>
      {showConfirmDeleteDialog && (
        <DeleteLocationConfirmationDialog
          onCancel={() => setShowConfirmDeleteDialog(false)}
          onSave={deleteLocation}
        />
      )}
    </>
  )
}

export default MuseumLocationRow
