import styled from 'styled-components'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import PlusSVG from 'client/assets/svg/icon/plus_20.svg'
import MinusSVG from 'client/assets/svg/icon/minus_20.svg'
import ResetSVG from 'client/assets/svg/icon/refresh_20.svg'
import MapsResetSVG from 'client/assets/svg/icon/maps_reset_20.svg'
import NoCropSVG from 'client/assets/svg/icon/no_crop_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'

const Container = styled.div<{ bottom: number }>`
  position: absolute;
  right: 20px;
  bottom: ${({ bottom }) => bottom}px;
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-top: var(--spacing-xxsmall);
  }
`

const ViewControlButton = styled(IconOnlyButton)`
  box-sizing: border-box;
  width: var(--spacing-xlarge);
  height: var(--spacing-xlarge);
  border-radius: var(--spacing-xxsmall);
  background-color: var(--color-white);
  box-shadow: var(--elevation-01);
`

const ZoomControls = styled.div`
  border-radius: var(--spacing-xxsmall);
  background-color: var(--color-white);
  box-shadow: var(--elevation-01);

  > :first-child > ${ViewControlButton} {
    box-shadow: none;
    border-top-left-radius: var(--spacing-xxsmall);
    border-top-right-radius: var(--spacing-xxsmall);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :last-child > ${ViewControlButton} {
    box-shadow: none;
    border-top: 1px solid var(--color-grey-02);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--spacing-xxsmall);
    border-bottom-right-radius: var(--spacing-xxsmall);
  }
`

interface IViewControlProps {
  onZoomIn: () => void
  onZoomOut: () => void
  onReset: () => void
  onNoCrop?: () => void
  imageCroppingAvailable?: boolean
  bottom?: number
  className?: string
  disableZoomingIn?: boolean
  disableZoomingOut?: boolean
  disableResetting?: boolean
}
const ViewControls = ({
  onZoomIn,
  onZoomOut,
  onReset,
  onNoCrop,
  imageCroppingAvailable = false,
  bottom = 20,
  className,
  disableZoomingIn = false,
  disableZoomingOut = false,
  disableResetting = false
}: IViewControlProps) => {
  const placement = 'left'

  return (
    <Container className={className} bottom={bottom}>
      <ZoomControls>
        <Tooltip content={t('Zoom in')} placement={placement}>
          <div>
            <ViewControlButton
              disabled={disableZoomingIn}
              svgComponent={PlusSVG}
              onClick={onZoomIn}
            />
          </div>
        </Tooltip>
        <Tooltip content={t('Zoom out')} placement={placement}>
          <div>
            <ViewControlButton
              disabled={disableZoomingOut}
              svgComponent={MinusSVG}
              onClick={onZoomOut}
            />
          </div>
        </Tooltip>
      </ZoomControls>
      <Tooltip
        content={imageCroppingAvailable ? t('Reset crop') : t('Reset zoom')}
        placement={placement}
      >
        <div>
          <ViewControlButton
            disabled={disableResetting}
            svgComponent={imageCroppingAvailable ? ResetSVG : MapsResetSVG}
            onClick={onReset}
          />
        </div>
      </Tooltip>
      {onNoCrop && (
        <Tooltip content={t('No crop')} placement={placement}>
          <div>
            <ViewControlButton svgComponent={NoCropSVG} onClick={onNoCrop} />
          </div>
        </Tooltip>
      )}
    </Container>
  )
}

export default ViewControls
