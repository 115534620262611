/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { FormikErrors, useFormikContext } from 'formik'
import { BannerItem } from 'client/dsm/Banner/Banner'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import { FormikTranslationError } from 'client/components/TranslationForm/types'
import { GQLLocale } from 'shared/graphql/types/graphql'
import { IErrorMap } from 'client/types'
import { t } from 'client/i18n'
import { TKey } from 'shared/i18n/types/translationResources'
import { applyNontranslatableFieldToDefaultLocale } from './util'

const StyledBanner = styled(FormErrorBanner)`
  z-index: auto;
`

const AggregateErrorItem = styled(BannerItem)`
  font-weight: var(--font-weight-semi-bold);
`

const useFormikValidationCount = () => {
  const { isValidating } = useFormikContext()
  const prevIsValidatingRef = useRef(false)
  const [validationCount, setValidationCount] = useState(0)

  useEffect(() => {
    // just finished validating?
    if (!isValidating && prevIsValidatingRef.current === true) {
      setValidationCount((prev) => prev + 1)
    }
    prevIsValidatingRef.current = isValidating
  }, [isValidating])

  return validationCount
}

interface ErrorBannerProps {
  isSubmitting: boolean
  errors: FormikErrors<FormikTranslationError>
  selectedLocale: GQLLocale
  defaultLocale: GQLLocale
  locales: GQLLocale[]
  showInitialErrors?: boolean
  // Temporary flag to support moving users to new forms without showing translations
  translationsEnabled: boolean
}

const ErrorBanner = (props: ErrorBannerProps) => {
  const {
    selectedLocale,
    locales,
    errors,
    isSubmitting,
    defaultLocale,
    translationsEnabled = true,
    showInitialErrors = false
  } = props
  const [submittedErrors, setSubmittedErrors] = useState<FormikErrors<FormikTranslationError>>()
  const validationCount = useFormikValidationCount()

  useEffect(() => {
    // First validation complete and user wants to show the banner?
    // Note: Since Formik goes through multiple render cycles before a full validation occurs
    // we can't simply just run this on mount, we have to wait for the validation to finish.
    const shouldShowInitialErrors = validationCount === 1 && showInitialErrors

    if (isSubmitting || shouldShowInitialErrors) {
      const updatedErrors = applyNontranslatableFieldToDefaultLocale(errors, defaultLocale)
      setSubmittedErrors(updatedErrors)
    }
  }, [isSubmitting, errors, showInitialErrors, validationCount])

  // Strip any errors associated with translations that have been removed
  // between the last time you saved, and the next time you save.
  // Ex: Spanish has errors > Save > Error Banner shows up > Remove Spanish (don't hit save!)
  // Errors for Spanish in the above example should go away, hence editing persisted errors here,
  // based on changing list of locales in the form.
  useEffect(() => {
    if (!_.isEmpty(submittedErrors)) {
      const codes = _.map(locales, 'code')
      const persistedErrorLocaleCodes = _.keysIn(submittedErrors)
      const localesToRemove = _.difference(persistedErrorLocaleCodes, codes)

      setSubmittedErrors(_.omit(submittedErrors, localesToRemove))
    }
  }, [locales])

  if (_.isNil(selectedLocale) || _.isEmpty(submittedErrors)) {
    return null
  }

  // FormikErrors<FormikTranslationError>
  // Casting here because FormikTranslationError can be an object or a string but selectedLocaleErrorMap is always an object
  const selectedLocaleErrorMap = _.get(submittedErrors, selectedLocale.code) as unknown as IErrorMap

  const AggregateError = () => {
    if (!translationsEnabled) {
      return null
    }

    const localeNames = _(locales)
      .filter((locale) => _.has(submittedErrors, locale.code))
      .map((locale) => {
        const languageName = t(`language.${locale.code}` as TKey)
        return locale.code === defaultLocale.code
          ? `${languageName} - ${t('Default')}`
          : languageName
      })
      .value()

    const aggregateErrorsMessage = t('translationFormValidationLanguagesWithErrorsMessage', {
      count: localeNames.length,
      translations: localeNames
    })

    return (
      <AggregateErrorItem key={aggregateErrorsMessage}>{aggregateErrorsMessage}</AggregateErrorItem>
    )
  }

  return (
    <StyledBanner errorMap={selectedLocaleErrorMap}>
      <AggregateError />
    </StyledBanner>
  )
}

export default ErrorBanner
