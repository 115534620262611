import SearchSVG from 'client/assets/svg/icon/search_20.svg'
import CancelSVG from 'client/assets/svg/icon/x_20.svg'
import styled from 'styled-components'
import _ from 'lodash'
import { t } from 'client/i18n'

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-grey-05);
  border-radius: 2px;
  &:focus-within {
    border-color: var(--color-accent-01);
  }
`

const SearchIconContainer = styled.div`
  margin-left: 8px;
`

const Input = styled.input`
  flex: 1 1 0;
  height: 40px;
  padding: 0 8px;
  background: transparent;
  border: none;
  &:focus {
    outline: none !important;
    border: none !important;
  }
  &:hover {
    outline: none !important;
    border: none !important;
  }
`

const CancelIconContainer = styled.div`
  padding-right: 8px;
  cursor: pointer;
  color: var(--color-grey-06);
  &:hover {
    color: var(--color-accent-01);
  }
  svg {
    color: currentColor;
  }
`

export interface ISearchInputProps {
  value: string
  placeholder?: string
  onChange: (value: string) => void
  onCancel: () => void
  className?: string
}

export default function SearchInput(props: ISearchInputProps) {
  const { value, placeholder = t('Filter by keyword'), onChange, onCancel, className } = props

  return (
    <SearchInputContainer className={className}>
      <SearchIconContainer>
        <SearchSVG />
      </SearchIconContainer>
      <Input
        placeholder={placeholder}
        autoComplete="off"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={true}
      />
      {!_.isEmpty(value) && (
        <CancelIconContainer onClick={onCancel}>
          <CancelSVG />
        </CancelIconContainer>
      )}
    </SearchInputContainer>
  )
}
