import _ from 'lodash'
import { Dialog, DialogHeader, DialogBody, DialogActions } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import { GQLLocale } from 'shared/graphql/types/graphql'
import { TKey } from 'shared/i18n/types/translationResources'

interface SaveDialogProps {
  added: GQLLocale[]
  removed: GQLLocale[]
  onSave: () => void
  onCancel: () => void
}

const SaveDialog = (props: SaveDialogProps) => {
  const { added = [], removed = [], onSave, onCancel } = props
  const addedNames = added.map(({ code }) => t(`language.${code}` as TKey))
  const removedNames = removed.map(({ code }) => t(`language.${code}` as TKey))
  return (
    <Dialog>
      <DialogHeader>{t('Save Changes?')}</DialogHeader>
      <DialogBody>
        {!_.isEmpty(added) && (
          <p>{t('languagesWillBeAdded', { translations: addedNames, count: addedNames.length })}</p>
        )}
        {!_.isEmpty(removed) && (
          <p>
            {t('languagesWillBeRemoved', {
              translations: removedNames,
              count: removedNames.length
            })}
          </p>
        )}
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="primary" onClick={onSave} label={t('Save')} />
      </DialogActions>
    </Dialog>
  )
}

export default SaveDialog
