import { t } from 'client/i18n'
import FormField from 'client/components/Form/FormField/FormField'
import styled from 'styled-components'
import SelectBox, { ISelectBoxProps } from 'client/components/Form/SelectBox/SelectBox'
import _ from 'lodash'
import { TKey } from 'shared/i18n/types/translationResources'
import useField from 'client/hooks/useField'
import { GQLLocale } from 'shared/graphql/types'
import GuideVisibilityType from 'shared/GuideVisibilityType'

const DefaultLanguageSelectBox = styled(SelectBox)`
  margin-top: var(--spacing-xsmall);
`

function getExplanatoryText(
  guideId: number,
  visibilityType: GuideVisibilityType,
  filteredLocales: GQLLocale[]
) {
  if (!guideId) {
    return null
  }

  if (visibilityType === GuideVisibilityType.PUBLIC && filteredLocales.length > 1) {
    return t(
      'Once a guide is public, you can only switch between different locales of the same language.'
    )
  }

  if (visibilityType === GuideVisibilityType.PUBLIC && filteredLocales.length <= 1) {
    return t(
      'You cannot change the default language for this guide.  Contact engineering with questions.'
    )
  }

  return t(
    "You can change the guide's default language.  Content in the current default language will continue to exist as a translation.  Where present, content in the new default language replaces the existing default."
  )
}

interface LanguageSelectorProps {
  guideId: number
  visibilityType: GuideVisibilityType
  locales: GQLLocale[]
}
export default function LanguageSelector({
  guideId,
  visibilityType,
  locales
}: LanguageSelectorProps) {
  const { value: defaultLocaleId, setValue: setDefaultLocaleId } = useField('defaultLocaleId')

  const selectedLocale = defaultLocaleId
    ? _.find(locales, {
        id: defaultLocaleId
      })
    : null

  const filteredLocales =
    guideId && selectedLocale && visibilityType === GuideVisibilityType.PUBLIC
      ? _.filter(locales, { languageCode: selectedLocale.languageCode })
      : locales ?? []

  const localesOptions = _.map(filteredLocales, ({ id, code }) => ({
    value: `${id}`,
    label: `${t(`language.${code}` as TKey)} (${code})`
  }))

  const selectedLocaleOption: any = _.find(localesOptions, {
    value: defaultLocaleId?.toString()
  })

  const onLocaleSelect: ISelectBoxProps['onChange'] = (e) => {
    setDefaultLocaleId(parseInt(e.target.value, 10))
  }

  const disabled = filteredLocales.length <= 1

  return (
    <FormField
      label={t('* Default Language')}
      disabled={disabled}
      description={getExplanatoryText(guideId, visibilityType, filteredLocales)}
    >
      <DefaultLanguageSelectBox
        name="defaultLocaleId"
        value={selectedLocaleOption}
        onChange={onLocaleSelect}
        options={localesOptions}
        isDisabled={disabled}
      />
    </FormField>
  )
}
