import {
  FeatureFlagKey,
  FeatureFlags,
  FeatureFlags as FeatureFlagsShape
} from 'shared/FeatureFlags'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'

let featureFlags: FeatureFlags

const FEATURE_FLAGS_QUERY = gql`
  query guideFeatureFlagsResolved($guideId: Int!) {
    resolvedFeatureFlags(guideId: $guideId) {
      name
      value
    }
  }
`

export function useFeatureFlags() {
  const versionInfo = useSelector(getSelectedVersionInfo)

  const { data, loading } = useQuery(FEATURE_FLAGS_QUERY, {
    skip: !!featureFlags || !versionInfo,
    variables: { guideId: versionInfo?.guideId }
  })

  if (!featureFlags && !loading && data) {
    const keyedFeatureFlags = _(data.resolvedFeatureFlags).keyBy('name').mapValues('value').value()
    featureFlags = _.mapValues(FeatureFlagKey, (value, key) => Boolean(keyedFeatureFlags[key]))
  }

  return featureFlags as FeatureFlagsShape
}
