import React, { useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import {
  BulkUploadErrorDetails,
  BulkUploadErrorType,
  BulkUploadFileStructureErrorDetails,
  BulkUploadFileStructureValidationInfo,
  IBulkUploadError
} from 'shared/bulkUpload/BulkUploadError'
import ErrorBanner from 'client/dsm/Banner/Banner'
import SupportLink from 'client/components/SupportLink'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import { Body1CSS, Header3CSS } from 'client/components/TextStyles'
import { SUPPORTED_AUDIO_FORMATS } from 'shared/constants/audio'
import { SUPPORTED_VIDEO_FORMATS } from 'shared/constants/video'

import { SUPPORTED_IMAGE_FORMATS } from 'shared/constants/images'

const ErrorContainer = styled.div`
  ${Body1CSS};
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

const ErrorContentContainer = styled.div`
  padding: var(--container-padding);
`

const Header = styled.div`
  ${Header3CSS};
  margin-bottom: var(--spacing-xsmall);
`

const ErrorListRowContainer = styled.div`
  padding-bottom: var(--spacing-small);
`

const ErrorListUl = styled.ul`
  margin-bottom: 0px;
`

interface IErrorList {
  details: IBulkUploadError['details']
}
const ErrorList = ({ details }: IErrorList) => {
  const renderDetailRow = ({ row, messages }: { row?: number; messages: string[] }) => (
    <React.Fragment key={`${row}-${messages}`}>
      {_.isFinite(row) && <div>{t('Row __row__', { row })}</div>}
      <ErrorListUl>
        {_.map(messages, (msg) => (
          <li key={msg}>{msg}</li>
        ))}
      </ErrorListUl>
    </React.Fragment>
  )

  return (
    <>
      {_.map(details as BulkUploadErrorDetails, ({ sheetName, errors }) => (
        <ErrorListRowContainer key={sheetName}>
          <strong>{sheetName}</strong>
          {errors.map(renderDetailRow)}
        </ErrorListRowContainer>
      ))}
    </>
  )
}

interface IBulkUploadErrorViewComponentProps {
  error: IBulkUploadError
}

const ParseErrorView = () => (
  <>
    <Header>{t('Choose a different file.')}</Header>
    <p>
      {t(
        'Bulk upload only supports XLSX and ZIP file types (ZIP files must only contain CSV files).'
      )}
    </p>
  </>
)

const createStructureErrorMessages = (
  { unrecognized, duplicate, missing }: BulkUploadFileStructureValidationInfo,
  label: 'columns' | 'sheets'
) =>
  _.compact([
    unrecognized?.length &&
      t(`bulkUploadValidationFieldLabel.Remove or rename unrecognized ${label}: __unrecognized__`, {
        unrecognized: unrecognized.join(', ')
      }),
    duplicate?.length &&
      t(`bulkUploadValidationFieldLabel.Remove or rename duplicate ${label}: __duplicates__`, {
        duplicates: duplicate.join(', ')
      }),
    missing?.length &&
      t(`bulkUploadValidationFieldLabel.Add missing ${label}: __missing__`, {
        missing: missing.join(', ')
      })
  ])

const FileStructureErrorView = ({ error }: IBulkUploadErrorViewComponentProps) => {
  const { sheets, columns } = error.details as BulkUploadFileStructureErrorDetails

  const columnDetails = useMemo<BulkUploadErrorDetails>(
    () =>
      _.map(columns, (columnErrorData, sheetName) => ({
        sheetName,
        errors: [{ messages: createStructureErrorMessages(columnErrorData!, 'columns') }]
      })),
    [columns]
  )

  const details = useMemo<BulkUploadErrorDetails>(() => {
    if (!sheets) {
      return columnDetails
    }
    return [
      {
        sheetName: t('Uploaded Sheets'),
        errors: [{ messages: createStructureErrorMessages(sheets, 'sheets') }]
      },
      ...columnDetails
    ]
  }, [columnDetails, sheets])

  return (
    <>
      <Header>{t('Update the sheet and/or column names in your upload file.')}</Header>
      <ul>
        <li>{t('All sheet and column names must match the names in the Starter Template.')}</li>
        <li>
          {t(
            'All sheets and columns in the Starter Template must be present in your upload file, even if they are left blank.'
          )}
        </li>
      </ul>
      <ErrorList details={details} />
    </>
  )
}

const FileDataErrorView = ({ error }: IBulkUploadErrorViewComponentProps) => {
  const details = error.details as BulkUploadErrorDetails
  const sheetNames = useMemo(() => _.map(details, 'sheetName'), [details])
  return (
    <>
      <Header>{t('Update the data in your upload file.')}</Header>
      <p>{t('The following sheets require updates: __sheetNames__', { sheetNames })}</p>
      <ErrorList details={details} />
    </>
  )
}

const MediaProcessingErrorView = ({ error }: IBulkUploadErrorViewComponentProps) => {
  const details = error.details as BulkUploadErrorDetails
  return (
    <>
      <Header>{t('Update the format of media files referenced by your upload file.')}</Header>
      <div>
        {t('Bulk upload only supports the following:')}
        <ul>
          <li>{t('Audio: __formats__ files', { formats: SUPPORTED_AUDIO_FORMATS })}</li>
          <li>{t('Image: __formats__ files', { formats: SUPPORTED_IMAGE_FORMATS })}</li>
          <li>{t('Video: __formats__ files', { formats: SUPPORTED_VIDEO_FORMATS })}</li>
        </ul>
      </div>
      <p>{t('The following sheets reference files that require updates:')}</p>
      <ErrorList details={details} />
    </>
  )
}

const BulkUploadErrorViews = {
  [BulkUploadErrorType.PARSE_ERROR]: ParseErrorView,
  [BulkUploadErrorType.FILE_STRUCTURE_ERROR]: FileStructureErrorView,
  [BulkUploadErrorType.FILE_DATA_ERROR]: FileDataErrorView,
  [BulkUploadErrorType.MEDIA_PROCESSING_ERROR]: MediaProcessingErrorView
}

const GenericErrorMessage = styled.div`
  ${Header3CSS};
  max-width: 720px;
`

const GenericErrorView = ({ message }: { message: string }) => {
  return (
    <ErrorContainer>
      <ErrorBanner
        type="error"
        headingText={t('Unable to complete bulk upload.')}
        description={t('Sorry, something went wrong. Try your request again.')}
      />
      <ErrorContentContainer>
        <Header>
          <Trans i18nKey="emailSupportWithIssues" components={{ SupportLink: <SupportLink /> }} />
        </Header>
        <GenericErrorMessage>{message}</GenericErrorMessage>
      </ErrorContentContainer>
    </ErrorContainer>
  )
}

interface IBulkUploadErrorViewProps {
  error: string | IBulkUploadError
}

const BulkUploadErrorView = ({ error }: IBulkUploadErrorViewProps) => {
  if (typeof error === 'string') {
    return <GenericErrorView message={error} />
  }

  const { type } = error
  const ErrorViewComponent = type && BulkUploadErrorViews[type]
  const description =
    type === BulkUploadErrorType.MEDIA_PROCESSING_ERROR
      ? t('Your media files did not upload. All other content was uploaded successfully.')
      : t('Your upload was not processed and your changes did not take effect.')

  return (
    <ErrorContainer>
      <ErrorBanner
        type="error"
        headingText={t('Review the details below, then upload a new file.')}
        description={description}
      />
      <ErrorContentContainer>
        {ErrorViewComponent && <ErrorViewComponent error={error} />}
      </ErrorContentContainer>
    </ErrorContainer>
  )
}

export default BulkUploadErrorView
