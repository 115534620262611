import { Route, Routes } from 'react-router-dom'
import Actions from 'client/screens/Developer/Actions'
import AppsflyerLinkEditor from 'client/screens/Developer/AppsflyerLinkEditor'
import CopyGuide from 'client/screens/Developer/CopyGuide'
import CopyExhibit from 'client/screens/Developer/CopyExhibit'
import FeatureFlags from 'client/screens/Developer/FeatureFlags'

export default function Developer() {
  return (
    <Routes>
      <Route path="actions" element={<Actions />} />
      <Route path="copy-guide" element={<CopyGuide />} />
      <Route path="copy-exhibit" element={<CopyExhibit />} />
      <Route path="appsflyer-link-editor" element={<AppsflyerLinkEditor />} />
      <Route path="feature-flags/*" element={<FeatureFlags />} />
    </Routes>
  )
}
