import _ from 'lodash'
import { APIProvider, Map, MapCameraChangedEvent, RenderingType } from '@vis.gl/react-google-maps'
import GoogleMapsMarker from 'client/screens/AppEditor/MapEditor/GoogleMapsMarker'
import { IGoogleMapsInfo, IGoogleMapsPinData } from 'client/types'
import { IMapJson } from 'shared/json/IMapJson'
import { useMemo } from 'react'
import GoogleMapsControls from './GoogleMapsControls'

const { GOOGLE_MAPS_API_KEY } = window

interface IGoogleMapsPreviewProps {
  map: Partial<IMapJson>
  onCenterChanged: (center: google.maps.LatLngLiteral | null) => void
  googleMapsInfo: IGoogleMapsInfo
}

const GoogleMapsPreview = ({ map, onCenterChanged, googleMapsInfo }: IGoogleMapsPreviewProps) => {
  const { mapLocations } = map
  const locations: IGoogleMapsPinData[] = useMemo(
    () =>
      _(mapLocations)
        .filter(
          (mapLocation) => !_.isNil(mapLocation?.latitude) && !_.isNil(mapLocation?.longitude)
        )
        .map(({ id, latitude, longitude }) => ({
          id: id!,
          key: `pin-${id}`,
          location: {
            lat: latitude!,
            lng: longitude!
          }
        }))
        .value(),
    [mapLocations]
  )

  const handleCenterChanged = (event: MapCameraChangedEvent) => {
    const { bounds } = event.detail
    // Ignore events with global bounds (0,0 center).
    // This is a workaround for the event hook giving bounds a default global bounds when the component on mount
    // need to avoid it so that it won't accidently override the mapCenter with {lat:0, lng:0}
    if (
      bounds.north === 90 &&
      bounds.south === -90 &&
      bounds.east === 180 &&
      bounds.west === -180
    ) {
      return
    }

    const center = {
      lat: (bounds.north + bounds.south) / 2,
      lng: (bounds.east + bounds.west) / 2
    }

    onCenterChanged(center)
  }

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <Map
        key={map.uuid}
        {...googleMapsInfo}
        mapId={`${map.id!}`}
        renderingType={RenderingType.VECTOR}
        // disable the Pegman on street view mode
        streetViewControl={false}
        // disable user interactions with other commercial pans in Google Maps
        clickableIcons={false}
        mapTypeControl={false}
        fullscreenControl={false}
        onCenterChanged={handleCenterChanged}
        cameraControl={false}
      >
        <GoogleMapsControls locations={locations} />
        <>
          {locations.map((location) => (
            <GoogleMapsMarker key={location.id} mapId={map.id!} location={location} />
          ))}
        </>
      </Map>
    </APIProvider>
  )
}

export default GoogleMapsPreview
