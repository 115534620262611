import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IGoogleMapsInfo } from 'client/types'
import { DEFAULT_ZOOM } from 'client/screens/AppEditor/MapEditor/constants'
import { useGuideCoordinates } from 'client/screens/AppEditor/MapEditor/useGuideCoordinates'
import { getDynamicBounds } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { IMapJson } from 'shared/json/IMapJson'

export const useGoogleMaps = (exteriorMap: IMapJson) => {
  const isMapInitialized = useRef(false)
  const {
    cameraCenterLatitude,
    cameraCenterLongitude,
    cameraHeading,
    cameraZoom,
    isGoogleMap,
    mapLocations
  } = exteriorMap ?? {}
  // TODO: need to handle error and loading state in new Advanced Exterior Map support
  const { guideLatitude, guideLongitude } = useGuideCoordinates()

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral | null>(null)

  const googleMapsLocations = _.filter(
    mapLocations,
    (mapLocation) => !_.isNil(mapLocation?.latitude) && !_.isNil(mapLocation?.longitude)
  )

  useEffect(() => {
    if (isGoogleMap && googleMapsLocations.length > 0 && !isMapInitialized.current) {
      const originalBounds = getDynamicBounds(googleMapsLocations)
      const originalCenter = {
        lat: (originalBounds.north + originalBounds.south) / 2,
        lng: (originalBounds.east + originalBounds.west) / 2
      }
      setMapCenter(originalCenter)
      isMapInitialized.current = true
    }
  }, [googleMapsLocations, isGoogleMap])

  const googleMapsInfo: IGoogleMapsInfo = useMemo(() => {
    if (!isGoogleMap) {
      return null
    }

    // if pins exist, fit pins to screen
    if (googleMapsLocations.length > 0) {
      // if there's only 1 pin, we will use the pin's lat/long as screen center and DEFAULT_ZOOM as defaultZoom.
      // Otherwise that single pin would look so zoomed in
      if (googleMapsLocations.length === 1) {
        return {
          defaultBounds: undefined,
          defaultCenter: {
            lat: googleMapsLocations[0].latitude!,
            lng: googleMapsLocations[0].longitude!
          },
          defaultZoom: DEFAULT_ZOOM,
          heading: cameraHeading
        }
      }

      return {
        defaultBounds: { ...getDynamicBounds(googleMapsLocations), padding: 10 },
        defaultCenter: undefined,
        defaultZoom: undefined,
        heading: cameraHeading
      }
    }

    // If camera center is provided, use it to render
    if (!_.isNil(cameraCenterLatitude) && !_.isNil(cameraCenterLongitude)) {
      return {
        defaultCenter: {
          lat: cameraCenterLatitude,
          lng: cameraCenterLongitude
        },
        defaultZoom: cameraZoom ?? DEFAULT_ZOOM,
        defaultBounds: undefined,
        heading: cameraHeading
      }
    }

    // If guide primary location lat/long is provided, use it to render
    if (!_.isNil(guideLatitude) && !_.isNil(guideLongitude)) {
      return {
        defaultCenter: { lat: guideLatitude, lng: guideLongitude },
        defaultZoom: cameraZoom ?? DEFAULT_ZOOM,
        defaultBounds: undefined
      }
    }

    // otherwise render the globe view
    return {
      defaultBounds: {
        north: 90,
        south: -90,
        east: 180,
        west: -180
      },
      defaultCenter: undefined,
      defaultZoom: undefined,
      heading: cameraHeading
    }
  }, [
    cameraCenterLatitude,
    cameraCenterLongitude,
    cameraHeading,
    cameraZoom,
    guideLatitude,
    guideLongitude,
    isGoogleMap,
    googleMapsLocations
  ])

  return {
    googleMapsInfo,
    mapCenter,
    setMapCenter
  }
}
