import GuideVisibilityType from 'shared/GuideVisibilityType'
import FormField from 'client/components/Form/FormField/FormField'
import styled from 'styled-components'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import ToggleInput, { ToggleInputProps } from 'client/components/Form/ToggleInput/ToggleInput'
import { useFormikContext, FormikContextType } from 'formik'
import _ from 'lodash'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface IGetTooltipContentParams {
  guideVisibilityType: GuideVisibilityType
  guideId: number
  isInternalTestingGuide: boolean
  hasDraft: boolean
  DEVELOPER_MODE: boolean
}
const getTooltipContent = ({
  guideVisibilityType,
  guideId,
  isInternalTestingGuide,
  hasDraft,
  DEVELOPER_MODE
}: IGetTooltipContentParams) => {
  if (!guideId) {
    return <p>{t('New guides must start out as private')}</p>
  }

  if (guideVisibilityType === GuideVisibilityType.SECRET) {
    return (
      <>
        <p>{t('This guide is secret and cannot be changed.')}</p>
        <p>{t('Contact CMS Engineering with questions.')}</p>
      </>
    )
  }

  if (DEVELOPER_MODE && guideVisibilityType === GuideVisibilityType.PUBLIC && hasDraft) {
    // don't need to translate this since it's developer-only
    /* eslint-disable i18next/no-literal-string */
    return (
      <>
        <p>
          This guide cannot be made private since it has both a live version and a draft, and it is
          unclear how to automatically proceed.
        </p>
        <p>
          You will need to work with the Partner Management team to either archive or promote the
          draft before you can mark this guide private again.
        </p>
      </>
    )
    /* eslint-enable i18next/no-literal-string */
  }

  if (!DEVELOPER_MODE && guideVisibilityType === GuideVisibilityType.PUBLIC) {
    return (
      <>
        <p>
          {t(
            'This guide is available to the public in the Bloomberg Connects app and cannot be made private.'
          )}
        </p>
        <p>{t('Contact CMS Engineering with questions.')}</p>
      </>
    )
  }

  if (isInternalTestingGuide) {
    return (
      <p>
        {t(
          'This is an internal testing guide and cannot be exposed to the public. Contact CMS Engineering with questions.'
        )}
      </p>
    )
  }

  return null
}

interface IPublicVisibilityFieldProps {
  originalGuideVisibilityType: GuideVisibilityType
  guideId: number
  hasDraft: boolean
}
const PublicVisibilityField = ({
  originalGuideVisibilityType,
  guideId,
  hasDraft
}: IPublicVisibilityFieldProps) => {
  const { DEVELOPER_MODE } = useFeatureFlags()
  const { values, setFieldValue } = useFormikContext() as FormikContextType<{
    visibilityType: GuideVisibilityType
    isInternal: boolean
  }>

  const disabledTooltipContent = getTooltipContent({
    guideVisibilityType: originalGuideVisibilityType,
    guideId,
    isInternalTestingGuide: values.isInternal,
    hasDraft,
    DEVELOPER_MODE
  })

  const disabled = !_.isNil(disabledTooltipContent)

  const value = values.visibilityType === GuideVisibilityType.PUBLIC

  const onChange: ToggleInputProps['onChange'] = (e) => {
    if (e.target.value) {
      setFieldValue('visibilityType', GuideVisibilityType.PUBLIC)
    } else {
      setFieldValue('visibilityType', GuideVisibilityType.PRIVATE)
    }
  }

  const toggle = <ToggleInput value={value} onChange={onChange} disabled={disabled} />

  return (
    <FlexContainer>
      <FormField
        label={t('Public Guide')}
        description={t(
          'Switch this setting on and then save the form to make this guide available to the public in the Bloomberg Connects app.'
        )}
        disabled={disabled}
      />
      {disabledTooltipContent ? (
        <Tooltip content={disabledTooltipContent}>
          <div>{toggle}</div>
        </Tooltip>
      ) : (
        toggle
      )}
    </FlexContainer>
  )
}

export default PublicVisibilityField
