import { useRef } from 'react'
import styled from 'styled-components'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import _ from 'lodash'
import { t } from 'client/i18n'
import { SUPPORTED_IMAGE_MIME_TYPES, SUPPORTED_LOGO_MIME_TYPES } from 'shared/constants/images'
import ImageAsset from './ImageAsset'

const Container = styled.div`
  display: flex;
  margin-top: var(--spacing-xsmall);
  justify-content: flex-start;
  align-items: center;
`

const ErrorContainer = styled.div`
  color: var(--color-status-error);
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-top: var(--spacing-small);
  svg {
    width: 14px;
    height: 14px;
  }
  *:first-child {
    margin-right: 5px;
  }
`

// Adding a 100% width here as a workaround to make error message has the right position
// This isn't necessary, the right fix should be modifying <Container> component to have 'flex-direction: column', and 'align-items: start'.
// However, by doing that the menu selector pop up has weird position, which I'm not sure why.
// Hence doing this 100% width hacky fix for now.
const StyledPlusIconAddButton = styled.div`
  width: 100%;
`

export interface IOptionFileInput {
  label: string
  isSvgAllowed: boolean
  onFileChange: (file: File) => void
  disable?: boolean
}

interface IProps {
  id: string
  src?: string | null
  fileInputOption: IOptionFileInput
  additionalOptions?: IMenuOption[]
  error?: string
  width?: number
  height?: number
  imageIsDisabled?: boolean
}

const AddImageAsset = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    id,
    src,
    error,
    fileInputOption = {} as IOptionFileInput,
    additionalOptions = [],
    width = 196,
    height = 112,
    imageIsDisabled
  } = props

  const clickFileInput = () => inputRef.current!.click()

  const options: IMenuOption[] = _.compact([
    ...additionalOptions,
    fileInputOption?.disable ? null : { label: fileInputOption.label, onClick: clickFileInput }
  ])

  const renderAssetView = () =>
    src ? (
      <ImageAsset src={src} width={width} height={height} imageIsDisabled={!!imageIsDisabled} />
    ) : (
      <StyledPlusIconAddButton>
        <PlusIconAddButton label={t('Add Image')} onClick={clickFileInput} />
      </StyledPlusIconAddButton>
    )

  const renderSelector = () => {
    if (!src) {
      return null
    }

    return <MenuPopout options={options} />
  }

  const renderInput = () => {
    if (!fileInputOption) {
      return null
    }

    const { isSvgAllowed, onFileChange } = fileInputOption
    const acceptedFileFormats = isSvgAllowed
      ? SUPPORTED_LOGO_MIME_TYPES
      : SUPPORTED_IMAGE_MIME_TYPES
    return (
      <input
        id={id}
        type="file"
        accept={acceptedFileFormats.join(',')}
        ref={inputRef}
        onChange={(event) => onFileChange(event.target.files![0])}
        onClick={() => {
          inputRef.current!.value = '' // TODO is this behavior equivalent?
        }}
        hidden={true}
      />
    )
  }

  const renderError = () => error && <ErrorContainer>{error}</ErrorContainer>

  return (
    <Container>
      {renderAssetView()}
      {renderError()}
      {renderSelector()}
      {renderInput()}
    </Container>
  )
}

export default AddImageAsset
