import { Formik, FormikProps } from 'formik'
import PopupWidget from 'client/components/PopupWidget/PopupWidget'
import FormHeader from 'client/components/Form/FormHeader'
import _ from 'lodash'
import { t } from 'client/i18n'
import styled from 'styled-components'
import { useState, PropsWithChildren, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import DiscardChangesDialog from 'client/components/DiscardChangesDialog'
import useFormikErrors from 'client/hooks/useFormikErrors'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import EnhancedStandardFormResourceType from 'shared/EnhancedStandardFormResourceType'
import { IErrorMap } from 'client/types'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'

const ContentContainer = styled.div`
  position: relative;
  overflow: auto;
  padding: var(--container-padding);
  background-color: var(--color-white);
  height: 100%;

  > *:first-child {
    &[data-showbackground='false'] {
      padding-top: 0px;
    }
  }
`

const ErrorBanner = ({ extraErrors }: { extraErrors?: IErrorMap }) => {
  const errors = useFormikErrors()
  return <FormErrorBanner errorMap={{ ...errors, ...extraErrors }} />
}

interface IEnhancedStandardFormProps extends PropsWithChildren {
  resourceType?: EnhancedStandardFormResourceType
  // optional title used if it doesn't follow "Add/Edit + resourceType" format
  titleOverride?: string
  contentId?: number
  validationSchema?: any
  initialValues: any
  onSubmit: (values: any) => void
  isLoading?: boolean
  onClose?: () => void
  onDelete?: () => void
  extraErrors?: IErrorMap
}

const EnhancedStandardForm = (props: IEnhancedStandardFormProps) => {
  const navigate = useNavigate()
  const {
    resourceType,
    titleOverride,
    contentId,
    validationSchema,
    initialValues,
    onSubmit,
    isLoading,
    onClose = () => navigate('..'),
    children,
    onDelete,
    extraErrors
  } = props
  const isFormCreating = _.isNil(contentId)
  let formTitle = ''
  if (titleOverride) {
    formTitle = titleOverride
  } else if (resourceType) {
    // eslint-disable-next-line docent/require-translation-keys-to-be-literals
    formTitle = t(`${isFormCreating ? 'Add' : 'Edit'} ${resourceType}`)
  }

  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false)

  const handleCancel = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setShowDiscardChangesDialog(true)
      } else {
        onClose()
      }
    },
    [onClose]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit: formikSubmit, dirty }: FormikProps<any>) => {
        return (
          <PopupWidget zIndex={10000}>
            {isLoading && <LoadingOverlay />}
            {showDiscardChangesDialog && (
              <DiscardChangesDialog
                onCancel={() => setShowDiscardChangesDialog(false)}
                onSave={onClose}
              />
            )}
            <FormHeader
              title={formTitle}
              onCancel={() => handleCancel(dirty)}
              onSave={formikSubmit}
              enableSave={true}
              onDelete={onDelete}
              allowDelete={!!contentId}
            />
            <ErrorBanner extraErrors={extraErrors} />
            <ContentContainer>{children}</ContentContainer>
          </PopupWidget>
        )
      }}
    </Formik>
  )
}

export default EnhancedStandardForm
