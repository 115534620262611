import PageContent from 'client/components/PageContent/PageContent'
import { useQuery } from '@apollo/client'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import Button from 'client/components/Button/Button'
import CopySVG from 'client/assets/svg/icon/copy_20.svg'
import { useDispatch } from 'react-redux'
import { flash } from 'client/redux/actions/flash'
import styled from 'styled-components'
import { renderToStaticMarkup } from 'react-dom/server'
import { GQLLocale, GQLMuseum } from 'shared/graphql/types/graphql'
import Label from 'client/components/Form/Label/Label'
import { LanguageInput } from 'client/components/Languages/LanguageInput'
import { useEffect, useMemo, useState } from 'react'
import useGuideLocalesQuery from 'client/hooks/useGuideLocalesQuery'
import { t } from 'client/i18n'
import { CMSTranslationMode } from 'shared/CMSTranslationMode'
import { CONTENT_SCREEN_SIZE_WITHOUT_PADDING } from 'client/constants'
import { GUIDE_EXPORT_GQL_QUERY } from './query'
import { GuideExportRenderer } from './components'

const PageContentContainer = styled.div`
  height: 100%;
  position: relative;
  padding: var(--container-padding);
  > * + * {
    margin-top: var(--spacing-large);
  }
  min-width: ${CONTENT_SCREEN_SIZE_WITHOUT_PADDING}px;
`

const ExportContainerWrapper = styled.div`
  border: var(--border-light);
`

const ExportContainer = styled.iframe`
  width: 100%;
  height: 455px;
  border: none;
  overflow: auto;
  mask-image: linear-gradient(to bottom, white 70%, transparent 100%);
`

const StyledLabel = styled(Label)`
  padding-bottom: 6px;
`

function getStaticHTMLForExportPreview(museum: GQLMuseum | undefined) {
  if (!museum) {
    return null
  }

  return `<html><body>${renderToStaticMarkup(
    <GuideExportRenderer content={museum} />
  )}</body></html>`
}

function GuideExportPreview({ html }: { html: string }) {
  return (
    <div>
      <StyledLabel>{t('Guide Export Preview')}</StyledLabel>
      <ExportContainerWrapper>
        <ExportContainer srcDoc={html} />
      </ExportContainerWrapper>
    </div>
  )
}

export default function GuideExport() {
  const dispatch = useDispatch()

  const [selectedLocale, setSelectedLocale] = useState<GQLLocale>()

  const { defaultLocale, availableLocales, loading: isLoadingLocales } = useGuideLocalesQuery()

  const { data, loading } = useQuery<{ museum: GQLMuseum }>(GUIDE_EXPORT_GQL_QUERY, {
    variables: {
      selectedLocaleCode: selectedLocale?.code,
      translationMode: CMSTranslationMode.STANDARD
    },
    skip: selectedLocale?.code === undefined // to skip duplicate query before we've fetched defaultLocale
  })

  useEffect(() => {
    if (!isLoadingLocales) {
      setSelectedLocale(defaultLocale)
    }
  }, [isLoadingLocales, defaultLocale])

  const museum = data?.museum
  const html = useMemo(() => getStaticHTMLForExportPreview(museum), [museum])

  const content =
    isLoadingLocales || loading ? (
      <LoadingOverlay loadingText={t('Generating Guide Export')} />
    ) : (
      <>
        <LanguageInput
          defaultLocale={defaultLocale}
          availableLocales={availableLocales}
          excludedLocales={[]}
          selectedLocale={selectedLocale}
          setSelectedLocale={setSelectedLocale}
        />
        <GuideExportPreview html={html!} />
        <Button
          label={t('Copy')}
          svgComponent={CopySVG}
          type="primary"
          onClick={async () => {
            const htmlBlob = new Blob([html!], { type: 'text/html' })
            const textBlob = new Blob([html!], { type: 'text/plain' })
            await navigator.clipboard.write([
              new ClipboardItem({
                [htmlBlob.type]: htmlBlob,
                [textBlob.type]: textBlob
              })
            ])
            dispatch(flash(t('Copied to clipboard')))
          }}
        />
      </>
    )

  return (
    <PageContent title={t('Guide Export')}>
      <PageContentContainer>{content}</PageContentContainer>
    </PageContent>
  )
}
