import { ImageFormat } from 'shared/constants/images'
import _ from 'lodash'

export const SupportedVideoTypes = {
  MP4: ['video/mp4'],
  MOV: ['video/quicktime'],
  AVI: ['video/x-msvideo', 'video/avi', 'video/msvideo'],
  WEBM: ['video/webm']
}

export const SUPPORTED_VIDEO_MIME_TYPES = _(SupportedVideoTypes).values().flatten().value()
export const SUPPORTED_VIDEO_FORMATS = _.keys(SupportedVideoTypes)

export const VIDEO_POSTER_IMAGE_GENERATION_SETTINGS = {
  format: ImageFormat.JPEG,
  // 720 is the max height dimension we re-encode videos to.
  dimensions: { width: 1280, height: 720 }
}
