import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'

interface IDeleteGuideConfirmDialogProps {
  onCancel: () => void
  onDelete: () => void
}

export function DeleteGuideConfirmDialog({ onCancel, onDelete }: IDeleteGuideConfirmDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Delete Guide?')}</DialogHeader>
      <DialogBody>
        <PermanentActionMessage />
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="danger" onClick={onDelete} label={t('Delete Guide')} />
      </DialogActions>
    </Dialog>
  )
}

interface IMakeGuidePublicConfirmDialogProps {
  onCancel: () => void
  onMakeGuidePublic: () => void
}

export function MakeGuidePublicConfirmDialog({
  onCancel,
  onMakeGuidePublic
}: IMakeGuidePublicConfirmDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Confirm Legal Agreement & Make Public')}</DialogHeader>
      <DialogBody>
        <p>
          {t(
            'To make this guide available to the public, confirm that this partner has executed the Content Submission Agreement.'
          )}
        </p>
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="primary" onClick={onMakeGuidePublic} label={t('Confirm & Make Public')} />
      </DialogActions>
    </Dialog>
  )
}

interface IMakeGuidePrivateConfirmDialogProps {
  onCancel: () => void
  onMakeGuidePrivate: () => void
}

export function MakeGuidePrivateConfirmDialog({
  onCancel,
  onMakeGuidePrivate
}: IMakeGuidePrivateConfirmDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Make Guide Private')}</DialogHeader>
      <DialogBody>
        {/* eslint-disable i18next/no-literal-string */}
        <p>Making this guide private removes it from the app.</p>
        <p>The live version of this guide will become a draft.</p>
        <p>
          Once a guide is private, Partners can no longer view the Data Dashboard. If the guide ever
          goes public again, the original publicDate will be overwritten, so the Data Dashboard will
          only show data from the latest publicDate.
        </p>
        {/* eslint-enable i18next/no-literal-string */}
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="primary" onClick={onMakeGuidePrivate} label={t('Make Guide Private')} />
      </DialogActions>
    </Dialog>
  )
}
