/* eslint-disable i18next/no-literal-string */
import { GQLImage } from 'shared/graphql/types/graphql'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import gql from 'graphql-tag'
import _ from 'lodash'
import {
  FILE_NAME_COLUMN,
  LANGUAGES_COLUMN,
  MEDIA_COLUMN_IMAGE_PROPS,
  UPDATED_AT_COLUMN,
  DOWNLOAD_COLUMN,
  MEDIA_INCLUDED_IN_COLUMN,
  MEDIA_INCLUDED_IN_FILTER_CRITERIA
} from 'client/screens/Catalog/grids/shared/columns'
import HTMLText from 'client/components/HTMLText/HTMLText'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { UploadMediaType } from 'client/hooks/useMediaUpload'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import EmptyStateGridImageSVG from 'client/assets/svg/emptyStates/empty_state_grid_image.svg'
import { SupportedImageTypes } from 'shared/constants/images'
import GridPlusIconAddButton from './shared/GridPlusIconAddButton'
import ImageForm from '../forms/ImageForm'
import ImagePreview from './renderers/ImagePreview'
import {
  EmptyStatePlaceholderDescription,
  EmptyStatePlaceholderDragDropFooter
} from './shared/styledComponents'

const captionValue = ({ cellData }: ITableCellProps<GQLImage>) => <HTMLText html={cellData} />

const IMAGES_GRAPHQL_QUERY = gql`
  query imagesForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      contentImages {
        id
        url
        hero
        fileName
        caption
        altText
        sourceUrl
        size
        isMarketingUseAllowed
        externalId
        isProcessing
        isChildrenProcessingError
        locales {
          code
          englishName
        }
        updatedAt
        items {
          title
          commonName
          scientificName
          creators {
            name
          }
        }
        exhibits {
          title
        }
      }
    }
  }
`
const addImageButton = <GridPlusIconAddButton label={t('Add Image')} to="/catalog/images/new" />

const ImagesEmptyPlaceholder = () => (
  <>
    <Header3>{t('Upload Image Files')}</Header3>
    <EmptyStatePlaceholderDescription centerAlign={true}>
      <p>{t('Add images to use in your Items, Exhibitions, Tours, and Events.')}</p>
      <EmptyStatePlaceholderDragDropFooter addButtonNode={addImageButton} />
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Images() {
  const { MARKETING } = useFeatureFlags()
  return (
    <BaseGridView
      contentName="Image"
      gqlQuery={IMAGES_GRAPHQL_QUERY}
      columns={_.compact([
        {
          dataKey: 'hero',
          label: t('Preview'),
          cellRenderer: ImagePreview,
          ...MEDIA_COLUMN_IMAGE_PROPS,
          sortable: false
        },
        FILE_NAME_COLUMN,
        { dataKey: 'caption', label: t('Caption'), cellRenderer: captionValue },
        { dataKey: 'altText', label: t('Alt-Text') },
        MEDIA_INCLUDED_IN_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        MARKETING && DOWNLOAD_COLUMN
      ])}
      filterCriteria={['caption', 'altText', 'fileName', ...MEDIA_INCLUDED_IN_FILTER_CRITERIA]}
      buttons={addImageButton}
      formComponent={ImageForm}
      uploadMediaType={UploadMediaType.IMAGE}
      droppableFileFormats={SupportedImageTypes}
      placeholder={<ImagesEmptyPlaceholder />}
      placeholderIcon={<EmptyStateGridImageSVG />}
    />
  )
}
