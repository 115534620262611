// No need for translations here, it's purely for marketing use */
/* eslint-disable i18next/no-literal-string, docent/no-plain-text-jsx-attributes */
import { useFilePicker } from 'use-file-picker'
import Button from 'client/components/Button/Button'
import PageContent from 'client/components/PageContent/PageContent'
import UploadSVG from 'client/assets/svg/icon/upload_20.svg'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import download from 'downloadjs'
import { useState } from 'react'
import axios, { AxiosError } from 'axios'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import { EmptyView } from 'client/components/PageContent/EmptyView'
import styled from 'styled-components'
import DownloadSVG from 'client/assets/svg/icon/download_20.svg'
import resolveUnknownError from 'shared/util/resolveUnknownError'
import { SelectedFiles } from 'use-file-picker/dist/interfaces'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import GuideConfirmationDialogForPdfUpload from './GuideConfirmationDialogForPdfUpload'

const SPREADSHEET_ACCEPTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

const PDF_ACCEPTED_FILE_TYPE = 'application/pdf'

const GUIDE_QUERY = gql`
  query guide($guideId: Int!) {
    guide(id: $guideId) {
      id
      name
      code
    }
  }
`

interface PageControlsProps {
  uploadDisabled: boolean
  onSpreadsheetUpload: () => void
  onPdfUpload: () => void
  guideName: string
}

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--spacing-medium);
  row-gap: var(--spacing-small);
`

const ButtonContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-medium);
`

const PageControls = (props: PageControlsProps) => {
  const { onSpreadsheetUpload, onPdfUpload, uploadDisabled, guideName } = props
  const [showGuideConfirmationDialog, setShowGuideConfirmationDialog] = useState(false)

  return (
    <ControlsContainer>
      <Button
        type="tertiary"
        onClick={() => window.open('/api/qrcodes/marketing/template', '_self')}
        svgComponent={DownloadSVG}
        label="Download Spreadsheet Template"
      />
      <ButtonContainer>
        <Button
          onClick={onSpreadsheetUpload}
          disabled={uploadDisabled}
          svgComponent={UploadSVG}
          label="Upload Spreadsheet"
        />
        <Button
          onClick={() => setShowGuideConfirmationDialog(true)}
          disabled={uploadDisabled}
          svgComponent={UploadSVG}
          label="Upload PDF"
        />
      </ButtonContainer>
      {showGuideConfirmationDialog && (
        <GuideConfirmationDialogForPdfUpload
          guideName={guideName}
          onClose={() => setShowGuideConfirmationDialog(false)}
          onUpload={onPdfUpload}
        />
      )}
    </ControlsContainer>
  )
}

const Container = styled.div`
  width: 100%;
  padding: var(--spacing-large);
`

enum FileType {
  SPREADSHEET = 'spreadsheet',
  PDF = 'pdf'
}

function useFileUpload(fileType: FileType, guideCode?: string) {
  const [isUploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const isPdf = fileType === FileType.PDF
  const endpoint = isPdf
    ? `/api/qrcodes/marketing/generate-from-pdf/${guideCode}`
    : '/api/qrcodes/marketing/generate-from-spreadsheet'
  const fileName = isPdf ? `${guideCode}_print_and_digital_order_output.zip` : 'qrcodes.zip'

  const upload = async (file: File) => {
    setUploading(true)
    setUploadError(null)
    const formData = new FormData()
    formData.append('file', file)
    try {
      const { data } = await axios.post(endpoint, formData, {
        responseType: 'blob'
      })
      download(data, fileName, 'application/zip')
    } catch (e) {
      if (e instanceof AxiosError) {
        const errorText = await e.response?.data.text()
        const error = JSON.parse(errorText)
        setUploadError(error.message || JSON.stringify(error, null, 4))
      } else {
        setUploadError(resolveUnknownError(e).message)
      }
    } finally {
      setUploading(false)
    }
  }

  return { upload, isUploading, uploadError }
}

export default function QrCodeGeneration() {
  const { data } = useQuery(GUIDE_QUERY)

  const {
    upload: uploadSpreadsheet,
    isUploading: isUploadingSpreadsheet,
    uploadError: spreadsheetUploadError
  } = useFileUpload(FileType.SPREADSHEET)
  const {
    upload: uploadPdf,
    isUploading: isUploadingPdf,
    uploadError: pdfUploadError
  } = useFileUpload(FileType.PDF, data?.guide?.code)

  const { openFilePicker: openSpreadsheetFilePicker } = useFilePicker({
    accept: SPREADSHEET_ACCEPTED_FILE_TYPES,
    onFilesSuccessfullySelected: ({ plainFiles }: SelectedFiles<ArrayBuffer>) =>
      uploadSpreadsheet(plainFiles[0])
  })

  const { openFilePicker: openPdfFilePicker } = useFilePicker({
    accept: PDF_ACCEPTED_FILE_TYPE,
    onFilesSuccessfullySelected: ({ plainFiles }: SelectedFiles<ArrayBuffer>) =>
      uploadPdf(plainFiles[0])
  })

  const isUploading = isUploadingSpreadsheet || isUploadingPdf

  return (
    <PageContent
      title="QR Code Generation"
      controls={
        <PageControls
          guideName={data?.guide?.name}
          onSpreadsheetUpload={openSpreadsheetFilePicker}
          onPdfUpload={openPdfFilePicker}
          uploadDisabled={isUploading}
        />
      }
    >
      {isUploading && <LoadingOverlay loadingText="Uploading..." />}
      {spreadsheetUploadError || pdfUploadError ? (
        <>
          <Banner type="error" headingText="QR Codes Not Generated">
            <BannerItem>Review the details below, then upload a new file.</BannerItem>
          </Banner>
          <Container>{spreadsheetUploadError || pdfUploadError}</Container>
        </>
      ) : (
        <EmptyView
          message={
            <>
              Click <strong>Upload Spreadsheet</strong> or <strong>Upload PDF</strong> to get
              started.
            </>
          }
        />
      )}
    </PageContent>
  )
}
