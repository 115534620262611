import * as yup from 'yup'
import { t } from 'client/i18n'
import _ from 'lodash'
import { GOOGLE_MAPS_MIN_ZOOM, GOOGLE_MAPS_MAX_ZOOM } from 'client/constants'

export const NAME_CHAR_LIMIT = 32

const MESSAGES = {
  INVALID_LATITUDE: `${t('Latitude')}: ${t('A valid number is required.')}`,
  INVALID_LONGITUDE: `${t('Longitude')}: ${t('A valid number is required.')}`,
  INVALID_CAMERA_HEADING: `${t('Map Orientation')}: ${t('A valid number is required.')}`,
  INVALID_MAP_TAB_ZOOM: `${t('Map Tab Zoom')}: ${t('A valid number is required.')}`,
  MISSING_LATITUDE: `${t('Latitude')}: ${t(
    'To customize coordinates, both latitude and longitude are required.'
  )}`,
  MISSING_LONGITUDE: `${t('Longitude')}: ${t(
    'To customize coordinates, both latitude and longitude are required.'
  )}`,
  INVALID_RANGE: {
    LATITUDE: `${t('Latitude')}: ${t(
      'Enter a valid latitude value in decimal degrees format (values range from -90 to 90).'
    )}`,
    LONGITUDE: `${t('Longitude')}: ${t(
      'Enter a valid longitude value in decimal degrees format (values range from -180 to 180).'
    )}`,
    CAMERA_HEADING: `${t('Map Orientation')}: ${t('Enter a value between 0 and 360.')}`,
    MAP_TAB_ZOOM: `${t('Map Tab Zoom')}: ${t('Enter a value between 0 and 22.')}`
  }
}

export const isValidNumber = (value: string) => {
  const trimmedValue = value.trim()
  const coordinatePattern = /^-?(\d+\.?\d*|\.\d+)$/

  return coordinatePattern.test(trimmedValue)
}

const isNotNullOrEmpty = (value: any) => !_.isNil(value) && value !== ''

const createNumberValidator = (isNullable: boolean) => (value: any) => {
  if (isNullable) {
    return isNotNullOrEmpty(value) ? isValidNumber(value) : true
  }

  return isNotNullOrEmpty(value) && isValidNumber(value)
}

const isWithinRange = (value: any, min: number, max: number) => {
  // if the value is not null or empty, simply give it a true to not triger is-within-range
  if (!isNotNullOrEmpty(value)) {
    return true
  }
  const num = parseFloat(value)
  return num >= min && num <= max
}

type CreateCoordinateSchemaFieldNames = 'Latitude' | 'Longitude' | 'Camera_Heading' | 'Map_Tab_Zoom'
const createCoordinateSchema = (
  fieldName: CreateCoordinateSchemaFieldNames,
  min: number,
  max: number,
  isNullable = false
) => {
  const schema = yup.string().nullable()

  if (!isNullable) {
    schema.required()
  }

  const upperCasedFieldName = fieldName.toUpperCase() as Uppercase<CreateCoordinateSchemaFieldNames>
  const messageKey = `INVALID_${upperCasedFieldName}` as const

  return schema
    .test('is-valid-number', MESSAGES[messageKey], createNumberValidator(isNullable))

    .test('is-within-range', MESSAGES.INVALID_RANGE[upperCasedFieldName], (value) =>
      isWithinRange(value, min, max)
    )
}

type CreatePairValidatorFieldNames = 'Latitude' | 'Longitude'
const createPairValidator = (fieldName: CreatePairValidatorFieldNames, otherFieldName: string) => ({
  name: `require-missing-${fieldName.toLowerCase()}`,
  test: function testFunc(value: any, context: yup.TestContext) {
    const otherValue = context.parent[otherFieldName]
    if (isNotNullOrEmpty(otherValue) && !isNotNullOrEmpty(value)) {
      const upperCasedFieldName =
        fieldName.toUpperCase() as Uppercase<CreatePairValidatorFieldNames>
      const key = `MISSING_${upperCasedFieldName}` as const
      return context.createError({ message: MESSAGES[key] })
    }
    return true
  }
})

export const MapSchemaClient = yup.object().shape({
  cameraCenterLatitude: createCoordinateSchema('Latitude', -90, 90, true).test(
    createPairValidator('Latitude', 'cameraCenterLongitude')
  ),
  cameraCenterLongitude: createCoordinateSchema('Longitude', -180, 180, true).test(
    createPairValidator('Longitude', 'cameraCenterLatitude')
  ),
  cameraHeading: createCoordinateSchema('Camera_Heading', 0, 360, true),
  cameraZoom: createCoordinateSchema(
    'Map_Tab_Zoom',
    GOOGLE_MAPS_MIN_ZOOM,
    GOOGLE_MAPS_MAX_ZOOM,
    true
  )
})

const SharedSchemaCore = {
  latitude: createCoordinateSchema('Latitude', -90, 90),
  longitude: createCoordinateSchema('Longitude', -180, 180)
}

export const BuildingSchemaCore = yup.object({
  name: yup.string().nullable().required()
})

export const BuildingFormSchemaClient = BuildingSchemaCore.shape(SharedSchemaCore)

export const PinSchemaCore = yup.object({
  name: yup.string().nullable().required().max(NAME_CHAR_LIMIT)
})

export const PinFormSchemaClient = PinSchemaCore.shape(SharedSchemaCore)
