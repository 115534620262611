import _ from 'lodash'
import { useRef, useEffect, useState, useLayoutEffect, useMemo } from 'react'
import * as React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useWindowSize from 'client/hooks/useWindowSize'
import { ICoordinates } from 'shared/util/maps'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { MAP_LOCATION_DEFAULT_RADIUS } from 'shared/constants/maps'
import { IMapJson } from 'shared/json/IMapJson'
import ViewControls from 'client/components/ViewControls/ViewControls'
import { t } from 'client/i18n'
import { Header3CSS } from 'client/components/TextStyles'
import GoogleMapsPreview from 'client/screens/AppEditor/MapEditor/GoogleMapsPreview'
import { IGoogleMapsInfo } from 'client/types'
import LocationForm from './LocationForm'
import { IDragEndEventProps } from './MapLocation/MapLocation'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-grey-01);
  overflow: hidden;
`

const PreviewHeader = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 70px;
  padding: 0px 24px;
  background-color: var(--color-white);
  box-shadow: var(--elevation-01);
`

const PreviewTitle = styled.div`
  ${Header3CSS};
  margin: 0;
`

const MAP_CONTAINER_PADDING = 15
const MapContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 70px);
  padding: ${MAP_CONTAINER_PADDING}px;
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  :hover {
    cursor: grab;
  }

  :active {
    cursor: grabbing;
  }
`

const PreviewImage = styled.img`
  position: absolute;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  transform: scale(1) translate(0, 0);
  @-moz-document url-prefix() {
    flex: initial;
  }
`

const SCALE_MAX = 2
const ZOOM_FACTOR_IN = 1.05
const ZOOM_FACTOR_OUT = 0.95

interface IMapPreviewProps {
  map: Partial<IMapJson>
  title?: string
  selectedPinId?: number | null
  onSelectedPinChanged?: (id: number) => void
  onDragEndLocation?: (args: IDragEndEventProps) => void
  onMapLocationResized?: (id: number, newRadius: number, coordinates?: ICoordinates) => void
  mapLocationElement: React.ElementType
  mapCenter?: google.maps.LatLngLiteral | null
  onCenterChanged?: (center: google.maps.LatLngLiteral | null) => void
  googleMapsInfo?: IGoogleMapsInfo
}

const MapPreview: React.FC<IMapPreviewProps> = (props: IMapPreviewProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const navigate = useNavigate()
  const {
    map = {},
    title,
    selectedPinId = null,
    mapLocationElement: MapLocationElement,
    onSelectedPinChanged = _.noop,
    onDragEndLocation = _.noop,
    onMapLocationResized = _.noop,
    mapCenter,
    onCenterChanged = _.noop,
    googleMapsInfo
  } = props

  const { width: naturalWidth, height: naturalHeight, mapLocations = [], isGoogleMap } = map
  const imageUrl = map.optimizedUrl || map.imageUrl

  const [scale, setScale] = useState({ current: 1, min: 1, max: 2 })
  const [renderedScale, setRenderedScale] = useState(1)
  const [translation, setTranslation] = useState<ICoordinates>({ x: 0, y: 0 })
  const [imageOffsetX, setImageOffsetX] = useState(0)
  const [imageOffsetY, setImageOffsetY] = useState(0)
  // used to avoid pins from showing while floor image is loading
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [currentImageUrl, setCurrentImageUrl] = useState<string>()
  const [windowWidth, windowHeight] = useWindowSize()
  const [isDraggable, setIsDraggable] = useState(false)
  const [dragPosition, setDragPosition] = useState<ICoordinates>({ x: 0, y: 0 })
  const [mouseDownPosition, setMouseDownPosition] = useState<ICoordinates | null>(null)
  const [startingDragPosition, setStartingDragPosition] = useState<ICoordinates>({ x: 0, y: 0 })

  const calculateScale = (scaleChange: number) => {
    setScale((previous) => ({
      ...previous,
      current: Math.min(previous.max, Math.max(previous.current * scaleChange, previous.min))
    }))
  }

  const getImageOffsets = () => {
    const { x: imageX, y: imageY } = imageRef?.current?.getBoundingClientRect() || { x: 0, y: 0 }
    const { x: containerX, y: containerY } = mapContainerRef?.current?.getBoundingClientRect() || {
      x: 0,
      y: 0
    }

    return {
      x: imageX - containerX,
      y: imageY - containerY
    }
  }

  useEffect(() => {
    const { x, y } = getImageOffsets()
    setImageOffsetX(x)
    setImageOffsetY(y)
  }, [windowWidth, windowHeight])

  const handleReset = () => {
    setScale((previous) => ({ ...previous, current: scale.min }))
    setTranslation({ x: 0, y: 0 })
  }

  useLayoutEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault()
      e.stopPropagation()
      calculateScale(0.99 ** e.deltaY)
    }

    const handleMouseMove = (e: MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      setDragPosition({ x: e.clientX, y: e.clientY })
    }

    const handleMouseDown = (e: MouseEvent) => {
      if (e.target === imageContainerRef.current) {
        window.addEventListener('mousemove', handleMouseMove)
        setMouseDownPosition({ x: e.clientX, y: e.clientY })
      }
    }

    const handleMouseUp = () => {
      setIsDraggable(false)
      window.removeEventListener('mousemove', handleMouseMove)
    }

    // Wheel listener added here, instead of as prop on element, due to below error in Chrome.
    // [Intervention] Unable to preventDefault inside passive event listener due to target being treated as passive.
    const currentMapContainerRef = mapContainerRef.current!
    currentMapContainerRef.addEventListener('wheel', handleWheel, { passive: false })
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousedown', handleMouseDown)
    return () => {
      currentMapContainerRef.removeEventListener('wheel', handleWheel)
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  useEffect(() => {
    if (isDraggable) {
      const distanceX = dragPosition.x - startingDragPosition.x
      const distanceY = dragPosition.y - startingDragPosition.y
      setTranslation(() => ({
        x: distanceX,
        y: distanceY
      }))
    }
    // TODO this seems to work as we have it, but the linter complains that we're missing the deps
    // commented out below. Simply adding those deps causes a cyclic dependency with the effect
    // below, so we'll leave this for now and come back to it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragPosition /* , isDraggable, startingDragPosition.x, startingDragPosition.y */])

  useEffect(() => {
    if (!_.isNil(mouseDownPosition)) {
      setIsDraggable(true)
      setStartingDragPosition({
        x: mouseDownPosition.x - translation.x,
        y: mouseDownPosition.y - translation.y
      })
    }
    // TODO this seems to work as we have it, but the linter complains that we're missing the deps
    // commented out below. Simply adding those deps causes a cyclic dependency with the effect
    // above, so we'll leave this for now and come back to it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseDownPosition /* , translation.x, translation.y */])

  useEffect(() => {
    imageRef.current!.style.transform = `scale(${scale.current}) translate(${
      translation.x / scale.current
    }px, ${translation.y / scale.current}px)`
    const { x, y } = getImageOffsets()
    // necessary to control exactly when locations get re-positioned -- rendering after transform avoids sloppy UI
    setRenderedScale(scale.current)
    setImageOffsetX(x)
    setImageOffsetY(y)
  }, [scale, translation])

  // Track image loading to avoid showing pins too early.
  useEffect(() => {
    setIsImageLoading(true)
    setCurrentImageUrl(imageUrl)
  }, [imageUrl])

  const onImageLoad = () => {
    const { width: containerWidth, height: containerHeight } =
      mapContainerRef?.current?.getBoundingClientRect() || { width: 0, height: 0 }
    const paddedContainerWidth = containerWidth - MAP_CONTAINER_PADDING * 2 // add padding for initial view
    const paddedContainerHeight = containerHeight - MAP_CONTAINER_PADDING * 2 // add padding for initial view
    const startingScale = Math.min(
      paddedContainerWidth / naturalWidth!,
      paddedContainerHeight / naturalHeight!
    )
    setScale({ current: startingScale, min: startingScale, max: SCALE_MAX })
    setTranslation({ x: 0, y: 0 })

    setTimeout(() => setIsImageLoading(false), 50) // slight delay helps for a smoother UI initialization
  }

  const calculateCoordinates = (coordinates: ICoordinates): ICoordinates => ({
    x: Math.max((coordinates.x - imageOffsetX) / scale.current, 0),
    y: Math.max((coordinates.y - imageOffsetY) / scale.current, 0)
  })

  const handleDragEndLocation = ({ id, coordinates }: IDragEndEventProps) => {
    onDragEndLocation({
      id,
      coordinates: calculateCoordinates(coordinates)
    })
  }

  const handleOnMapLocationResized = (newRadius: number, coordinates?: ICoordinates) => {
    const radius = newRadius / renderedScale
    const position = !_.isNil(coordinates) ? calculateCoordinates(coordinates) : undefined
    onMapLocationResized(selectedPinId, radius, position)
  }

  const handleToggleMapArea = (enabled: boolean, coordinates?: ICoordinates) => {
    const radius = enabled ? MAP_LOCATION_DEFAULT_RADIUS : 0
    const position = !_.isNil(coordinates) ? calculateCoordinates(coordinates) : undefined
    onMapLocationResized(selectedPinId, radius, position)
  }

  const getImageBounds = () => {
    const {
      x: imageX,
      y: imageY,
      width: imageWidth,
      height: imageHeight
    } = imageRef?.current?.getBoundingClientRect() || { x: 0, y: 0, width: 0, height: 0 }
    const { x: containerX, y: containerY } = mapContainerRef?.current?.getBoundingClientRect() || {
      x: 0,
      y: 0
    }
    const top = imageY - containerY
    const left = imageX - containerX
    return {
      left,
      right: left + imageWidth,
      top,
      bottom: top + imageHeight
    }
  }

  // ensure the loading state is associated with the latest image (limit to non-standard users for now)
  const canRenderLocations = !isImageLoading && currentImageUrl === imageUrl

  const renderLocations = () => {
    const imageBounds = getImageBounds()
    return mapLocations.map((location) => {
      const { id, xPosition, yPosition, radius } = location
      const isSelected = selectedPinId === id
      const updatedRadius = radius * renderedScale
      const diameter = updatedRadius * 2
      const x = xPosition * renderedScale + imageOffsetX
      const y = yPosition * renderedScale + imageOffsetY
      const bounds = {
        top: -(y - imageBounds.top),
        left: -(x - imageBounds.left),
        right: imageBounds.right - x,
        bottom: imageBounds.bottom - y
      }

      return (
        <MapLocationElement
          key={`location-${id}`}
          id={id}
          x={x}
          y={y}
          isSelected={isSelected}
          diameter={diameter}
          onMapAreaResized={handleOnMapLocationResized}
          onToggleMapArea={handleToggleMapArea}
          onSelected={onSelectedPinChanged}
          onDragEnd={handleDragEndLocation}
          bounds={bounds}
          mapContainer={imageContainerRef}
        />
      )
    })
  }

  const plane = useMemo(
    () => ({
      width: naturalWidth!,
      height: naturalHeight!
    }),
    [naturalHeight, naturalWidth]
  )

  return (
    <Container>
      <Routes>
        <Route path="pins/new" element={<LocationForm plane={plane} mapCenter={mapCenter} />} />
        <Route path="pins/:locationId" element={<LocationForm plane={plane} />} />
      </Routes>
      <PreviewHeader>
        <PreviewTitle>{title}</PreviewTitle>
        <PlusIconAddButton
          type="primary"
          label={t('Add Pin')}
          onClick={() => navigate('pins/new')}
        />
      </PreviewHeader>
      {isGoogleMap && googleMapsInfo ? (
        <GoogleMapsPreview
          map={map}
          onCenterChanged={onCenterChanged}
          googleMapsInfo={googleMapsInfo}
        />
      ) : (
        <>
          <MapContainer ref={mapContainerRef}>
            <ImageContainer ref={imageContainerRef} onClick={() => onSelectedPinChanged(null)}>
              <PreviewImage ref={imageRef} src={currentImageUrl} onLoad={onImageLoad} />
            </ImageContainer>
            {canRenderLocations && renderLocations()}
          </MapContainer>
          <ViewControls
            onZoomIn={() => calculateScale(ZOOM_FACTOR_IN)}
            onZoomOut={() => calculateScale(ZOOM_FACTOR_OUT)}
            onReset={handleReset}
          />
        </>
      )}
    </Container>
  )
}

export default MapPreview
