import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { useQuery } from '@apollo/client'
import { BaseFormFieldSection } from 'client/components/Form/FormField/FormFieldSection'
import { MapSchemaClient } from 'client/validation/Map'
import useField from 'client/hooks/useField'
import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import TextInput from 'client/components/Formik/TextInput/TextInput'
import gql from 'graphql-tag'
import { usePut } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { confirm } from 'client/redux/actions/confirmation'
import { createFormData } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { IMapJson } from 'shared/json/IMapJson'
import FormField from 'client/components/Form/FormField/FormField'
import { Trans } from 'react-i18next'
import {
  LatitudeContextualHelp,
  LongitudeContextualHelp,
  MapOrientationContextualHelp
} from './GoogleMapsContextualHelp'

type IGoogleMapsFormValues = Pick<
  IMapJson,
  'isGoogleMap' | 'cameraCenterLatitude' | 'cameraCenterLongitude' | 'cameraHeading' | 'cameraZoom'
>

const MAP_QUERY = gql`
  query getSingleMap($id: Int!) {
    map(id: $id) {
      id
      isGoogleMap
      cameraCenterLatitude
      cameraCenterLongitude
      cameraHeading
      cameraZoom
    }
  }
`

interface IUpdateExteriorMapValues extends IGoogleMapsFormValues {
  image?: File
}

const GoogleMapsForm = ({ exteriorMapId }: { exteriorMapId?: number }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EDIT_GOOGLE_MAPS_INFO } = useFeatureFlags()

  const [updateExteriorMap, isUpdatingExteriorMap] = usePut('/maps/exterior', {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to update the exterior map. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const onSubmit = async (values: IUpdateExteriorMapValues) => {
    const formData = createFormData(values)
    await updateExteriorMap(formData)
    // TODO: need to handle navigate back for all map forms in EnhancedStandardForm
    navigate('..')
  }

  const { data, loading: isFetchingExteriorMap } = useQuery<{ map: IGoogleMapsFormValues }>(
    MAP_QUERY,
    {
      variables: { id: exteriorMapId }
    }
  )

  const initialValues: IGoogleMapsFormValues = {
    isGoogleMap: data?.map?.isGoogleMap,
    cameraCenterLatitude: data?.map?.cameraCenterLatitude,
    cameraCenterLongitude: data?.map?.cameraCenterLongitude,
    cameraHeading: data?.map?.cameraHeading,
    cameraZoom: data?.map?.cameraZoom
  }

  const MapFormView = () => {
    const { value: isGoogleMap } = useField('isGoogleMap')
    return (
      <>
        {EDIT_GOOGLE_MAPS_INFO && (
          <>
            <ToggleField name="isGoogleMap" label={t('Google Maps Enabled')} />
            <BaseFormFieldSection />
          </>
        )}

        {isGoogleMap && (
          <>
            <FormField
              label=""
              description={
                <p>
                  <Trans i18nKey="advancedMapSettingsDescription" />
                </p>
              }
            />

            <BaseFormFieldSection label={t('Center Point Location')} hideDivider={true} />
            <TextInput
              name="cameraCenterLatitude"
              label={t('Latitude')}
              additionalLabelNode={LatitudeContextualHelp}
            />
            <TextInput
              name="cameraCenterLongitude"
              label={t('Longitude')}
              additionalLabelNode={LongitudeContextualHelp}
            />

            <BaseFormFieldSection />
            <TextInput
              name="cameraHeading"
              label={t('Map Orientation')}
              additionalLabelNode={MapOrientationContextualHelp}
            />
            <TextInput
              name="cameraZoom"
              label={t('Map Tab Zoom')}
              description={t(
                'The zoom level for the guide’s Map is dynamic and scales automatically to ensure that all pins are visible on all devices. Changing the zoom here might mean some pins do not appear in the default view.'
              )}
            />
          </>
        )}
      </>
    )
  }

  return (
    <EnhancedStandardForm
      titleOverride={t('Advanced Map Settings')}
      contentId={exteriorMapId}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={MapSchemaClient}
      isLoading={isUpdatingExteriorMap || isFetchingExteriorMap}
    >
      <MapFormView />
    </EnhancedStandardForm>
  )
}

export default GoogleMapsForm
