import _ from 'lodash'
import { HTMLElement, parse } from 'node-html-parser'
import isHTML from 'is-html'
import { Nil } from './types'

function getTextContent(html: string) {
  return parse(html).textContent
}

export function getPlainTextForHtml(value: Nil): null
export function getPlainTextForHtml(value: string): string
export function getPlainTextForHtml(value: string | Nil): string | null
export function getPlainTextForHtml(value: string | Nil) {
  return _.isNil(value) ? null : getTextContent(value)
}

export const getCharacterCountForHtml = (value: string): number =>
  value ? getPlainTextForHtml(value).length : 0

export function removePTagWrapper(text: string | Nil) {
  if (_.isNil(text) || !isHTML(text)) {
    return text
  }

  const nodes = parse(text)

  const firstNode = nodes.childNodes[0]

  if (
    firstNode instanceof HTMLElement &&
    (firstNode.tagName === 'p' || firstNode.tagName === 'P')
  ) {
    return firstNode.innerHTML
  }

  return text
}
