import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { SUPPORTED_IMAGE_MIME_TYPES } from 'shared/constants/images'

const Input = styled.input`
  display: none;
`

interface IMapImageInputProps {
  id: string
  onFileChange: (file: File) => void
  // eslint-disable-next-line react/no-unused-prop-types
  ref?: React.Ref<HTMLInputElement>
}

export default React.forwardRef((props: IMapImageInputProps, ref: React.Ref<HTMLInputElement>) => {
  const { id, onFileChange } = props
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = _.first<File>(event.target.files)!
    onFileChange(file)
    /**
     * Resetting target value to empty string allows the same file to be uploaded multiple times.
     * It's an edge case, but can happen.
     */
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }
  return (
    <Input
      id={id}
      type="file"
      onChange={handleChange}
      accept={SUPPORTED_IMAGE_MIME_TYPES.join(',')}
      ref={ref}
    />
  )
})
